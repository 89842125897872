import Table from 'react-bootstrap/Table';
import React from 'react'

function TablaOpcionesNegociacion(props) {

    function mostrarTabla() {
        if (props.estudio && props.estudio.plazo > 0) {
            let honorarios1 = Math.round(((props.estudio.tabla0.PagoTotal - props.estudio.tabla1.PagoTotal) * 0.05 * 1.19) < (props.estudio.valorCredito * 0.03 * 1.19) ? ((props.estudio.tabla0.PagoTotal - props.estudio.tabla1.PagoTotal) * 0.05 * 1.19) : (props.estudio.valorCredito * 0.03 * 1.19));
            let honorarios2 = Math.round(((props.estudio.tabla0.PagoTotal - props.estudio.tabla2.PagoTotal) * 0.05 * 1.19) < (props.estudio.valorCredito * 0.03 * 1.19) ? ((props.estudio.tabla0.PagoTotal - props.estudio.tabla2.PagoTotal) * 0.05 * 1.19) : (props.estudio.valorCredito * 0.03 * 1.19));
            let honorarios3 = Math.round(((props.estudio.tabla0.PagoTotal - props.estudio.tabla3.PagoTotal) * 0.05 * 1.19) < (props.estudio.valorCredito * 0.03 * 1.19) ? ((props.estudio.tabla0.PagoTotal - props.estudio.tabla3.PagoTotal) * 0.05 * 1.19) : (props.estudio.valorCredito * 0.03 * 1.19));
            honorarios1 = honorarios1 < 1785000 ? 1785000 : honorarios1;
            honorarios2 = honorarios2 < 1785000 ? 1785000 : honorarios2;
            honorarios3 = honorarios3 < 1785000 ? 1785000 : honorarios3;
            return (
                <div>
                    <Table className="table table-bordered border-dark"
                        size="sm"
                        responsive="sm"
                    >
                        <thead >
                            <tr className="text-center">
                                <th colSpan={9}>
                                    OPCIONES DE NEGOCIACIÓN
                                </th>
                            </tr>
                            <tr className="text-center">
                                <th>
                                    Condiciones
                                </th>
                                <th colSpan={2}>
                                    Banco actual
                                </th>
                                <th colSpan={2}>
                                    Propuesta 1
                                </th>
                                <th colSpan={2}>
                                    Propuesta 2
                                </th>
                                <th colSpan={2}>
                                    Propuesta 3
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Plazo</td>
                                <td align="center">{props.estudio.plazo}</td>
                                <td align="center">{(props.estudio.plazo / 12).toFixed(2)}</td>
                                <td align="center">{props.estudio.plazo1}</td>
                                <td align="center">{(props.estudio.plazo1 / 12).toFixed(2)}</td>
                                <td align="center">{props.estudio.plazo2}</td>
                                <td align="center">{(props.estudio.plazo2 / 12).toFixed(2)}</td>
                                <td align="center">{props.estudio.plazo3}</td>
                                <td align="center">{(props.estudio.plazo3 / 12).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <td>Valor cuota</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla0.CuotaTotalPesos[0]).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla1.CuotaTotalPesos[0]).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla2.CuotaTotalPesos[0]).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla3.CuotaTotalPesos[0]).toLocaleString()}</td>
                            </tr>
                            <tr>
                                <td>Interés</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla0.CuotaInteresPesos[0]).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla1.CuotaInteresPesos[0]).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla2.CuotaInteresPesos[0]).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla3.CuotaInteresPesos[0]).toLocaleString()}</td>
                            </tr>
                            <tr>
                                <td>Capital</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla0.CuotaCapitalPesos[0]).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla1.CuotaCapitalPesos[0]).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla2.CuotaCapitalPesos[0]).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla3.CuotaCapitalPesos[0]).toLocaleString()}</td>
                            </tr>
                            <tr>
                                <td>Frech</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla0.CuotaInteresPesosCobertura[0]).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla1.CuotaInteresPesosCobertura[0]).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla2.CuotaInteresPesosCobertura[0]).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla3.CuotaInteresPesosCobertura[0]).toLocaleString()}</td>
                            </tr>
                            <tr>
                                <td>Seguro</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.seguro).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.seguro1).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.seguro2).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.seguro3).toLocaleString()}</td>
                            </tr>
                            <tr>
                                <td>Total pagado</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla0.PagoTotal).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla1.PagoTotal).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla2.PagoTotal).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla3.PagoTotal).toLocaleString()}</td>
                            </tr>
                            <tr>
                                <td><div className="propuestaDetalleNegrita" >AHORRO</div></td>
                                <td align="right" colSpan={2}><div className="propuestaDetalleNegrita" >0</div></td>
                                <td align="right" colSpan={2}><div className="propuestaDetalleNegrita">${Math.round(props.estudio.tabla0.PagoTotal - props.estudio.tabla1.PagoTotal).toLocaleString()}</div></td>
                                <td align="right" colSpan={2}><div className="propuestaDetalleNegrita">${Math.round(props.estudio.tabla0.PagoTotal - props.estudio.tabla2.PagoTotal).toLocaleString()}</div></td>
                                <td align="right" colSpan={2}><div className="propuestaDetalleNegrita">${Math.round(props.estudio.tabla0.PagoTotal - props.estudio.tabla3.PagoTotal).toLocaleString()}</div></td>
                            </tr>
                            <tr>
                                <td>Cuota estratégica</td>
                                <td align="right" colSpan={2}>0</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla1.CuotaTotalPesos[0] - props.estudio.tabla0.CuotaTotalPesos[0]).toLocaleString() }</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla2.CuotaTotalPesos[0] - props.estudio.tabla0.CuotaTotalPesos[0]).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla3.CuotaTotalPesos[0] - props.estudio.tabla0.CuotaTotalPesos[0]).toLocaleString()}</td>
                            </tr>
                            <tr>
                                <td>Cuota con seguro</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla0.CuotaTotalPesos[0] - props.estudio.tabla0.CuotaInteresPesosCobertura[0] + props.estudio.seguro).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla1.CuotaTotalPesos[0] - props.estudio.tabla1.CuotaInteresPesosCobertura[0] + props.estudio.seguro1).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla2.CuotaTotalPesos[0] - props.estudio.tabla2.CuotaInteresPesosCobertura[0] + props.estudio.seguro2).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla3.CuotaTotalPesos[0] - props.estudio.tabla3.CuotaInteresPesosCobertura[0] + props.estudio.seguro3).toLocaleString()}</td>
                            </tr>
                            <tr>
                                <td colSpan={3}><div className="propuestaDetalleNegrita" >VALOR HONORARIOS (IVA incluido)</div></td>
                                <td align="right" colSpan={2}><div className="propuestaDetalleNegrita" >${honorarios1.toLocaleString()}</div></td>
                                <td align="right" colSpan={2}><div className="propuestaDetalleNegrita" >${honorarios2.toLocaleString()}</div></td>
                                <td align="right" colSpan={2}><div className="propuestaDetalleNegrita" >${honorarios3.toLocaleString()}</div></td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                )
        }
    }

    return (
        <>
            {mostrarTabla()}
        </>
    );
}
export default TablaOpcionesNegociacion;
