import "../css/error404.css"
import { GlobalDataContext } from "../components/context/globalData"
import React, { useEffect } from 'react';
import { useContext } from "react";

function Error404() {

    const gdc = useContext(GlobalDataContext);

    useEffect(() => {
        gdc.setShowMenu(true);
    })
        
    return (
        <div >
            <div className="error404Fondo">
                <hr />
                <div className="error404Titulo">
                    <h1 className="error-titulo">Error 404</h1>
                    <p className="zoom-area">Página no encontrada, verifique la URL y vuelva a intentarlo</p>
                </div>
                <section className="error-container">
                    <span>4</span>
                    <span><span className="screen-reader-text">0</span></span>
                    <span>4</span>
                </section>
                <div className="link-container">
                    <a href="/" className="more-link">Regresar al home</a>
                </div>
                <div>
                    <p></p>
                </div>
            </div>
        </div>
    )

}

export default Error404;