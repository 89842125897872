import React from 'react';
import Table from 'react-bootstrap/Table';

function TablaObservaciones(props) {

    var fecha = new Date().getTime() + (1000 * 60 * 60 * 24 * 30);
    fecha = new Date(fecha);
    const opciones = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

      
    function mostrarTabla() {
        if (props.estudio) {
            return (
                <Table className="table table-bordered border-dark" size="sm" >
                    <thead >
                        <tr className="text-center">
                            <th >
                                ACCIONES Y OBSERVACIONES
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="propuestaDetalleObservacionesValida" >
                            <td height="35px">PROPUESTA VÁLIDA HASTA EL {(fecha.toLocaleDateString('es-co', opciones ).toLocaleUpperCase())} </td>
                        </tr>
                        <tr>
                            <td height="35px"> </td>
                        </tr>
                        <tr>
                            <td height="35px"> </td>
                        </tr>
                        <tr>
                            <td height="35px"> </td>
                        </tr>
                    </tbody>
                </Table>
                )
        }
    }
    
    return (
        <div>
            {mostrarTabla()}
        </div>
    );
}
export default TablaObservaciones;