import "../../css/propuestaDetalle.css";
import fpilogo from "../../img/logo-fpi-2-2-18.png";
import TablaDatosCliente from "./tablaDatosCliente"
import TablaOpcionesNegociacion from "./tablaOpcionesNegociacion"
import GraficoComportamientoCredito from "./graficoComportamientoCredito"
import GraficoDistribucionCuota from "./graficoDistribucionCuota"
import TablaComportamientoDeuda from "./tablaComportamientoDeuda"
import TablaSeguro from "./tablaSeguro"
import TablaObservaciones from "./tablaObservaciones"
import GraficoComportamientoSeguros from "./graficoComportamientoSeguros"
import React from 'react';
import { useEffect, useState, useContext } from "react";
import { useParams, Link } from 'react-router-dom';
import { GlobalDataContext } from "../context/globalData"
import listarPropuestas from "../../services/listarPropuestas"

function PropuestaDetalle(props) {

    const [estudio, setEstudio] = useState([]);
    const gdc = useContext(GlobalDataContext);
    const id = useParams().id

    useEffect(() => {
        listarPropuestas(gdc.loggedUser, gdc.cliente.cedula, setEstudio);
        gdc.setShowMenu(false);
        document.body.style.backgroundColor = 'white';
    }, [gdc])

    return (
        <div className="">
            <div className="propuestaDetalleEncabezado">
                <Link to="/lista-clientes">
                <img className="fpi-logo-propuestaDetalle" src={fpilogo} alt="FPI" />
                </Link>
                <h4 className="propuestaDetalleTitulo">PROGRAMA DE BIENESTAR FINANCIERO</h4>
            </div>
            <div className="propuestaDetalleContenido">
                <div className="propuestaDetalleTablaDatosCliente">
                    <TablaDatosCliente estudio={estudio[id]} />
                </div>
                <div className="propuestaDetalleTablaOpcionesNegociacion">
                    <TablaOpcionesNegociacion estudio={estudio[id]} />
                </div>
                <div className="propuestaDetalleGraficoComportamientoCredito">
                    <GraficoComportamientoCredito estudio={estudio[id]} />
                </div>
                <div className="propuestaDetalleGraficoDistribucionCuota">
                    <GraficoDistribucionCuota estudio={estudio[id]} />
                </div>
                <div className="propuestaDetalleTablaComportamientoDeuda">
                    <TablaComportamientoDeuda estudio={estudio[id]} />
                </div>
                <div className="propuestaDetalleTablaSeguros">
                    <TablaSeguro estudio={estudio[id]} />
                </div>
                <div className="propuestaDetalleObservaciones">
                    <TablaObservaciones estudio={estudio[id]} />
                </div>
                <div className="propuestaDetalleGraficoComportamientoSeguros">
                    <GraficoComportamientoSeguros estudio={estudio[id]} />
                </div>
            </div>
        </div>
    )
}
export default PropuestaDetalle;
