import Table from 'react-bootstrap/Table';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import React from 'react'

function GraficoComportamientoSeguros(props) {

    let data;
    if (props.estudio) {
        data = [
            {
                name: 'Mensual',
                Actual: Math.round(props.estudio.seguro),
                FPI: Math.round(props.estudio.seguro1),

            },
            {
                name: 'Anual',
                Actual: Math.round(props.estudio.seguro * 12),
                FPI: Math.round(props.estudio.seguro1 * 12),

            },
        ];
    }

    const maximoNumero = () => {
        if (props.estudio) {
            return Math.max(data[1].Actual, data[1].FPI);
        }
        return 0;
    }


    class CustomizedLabel extends React.Component {

        render() {
            const { x, y, width, value } = this.props;
            return <text
                x={x + width / 8}
                y={y-3}
                fill="black"
            >
                ${value.toLocaleString()}
            </text>
        }
    }

    return (<>
        <div>
            <Table className="table table-bordered border-dark"
                size="sm"
                responsive="sm"
            >
                <thead >
                    <tr className="text-center">
                        <th colSpan={9}>
                            COMPORTAMIENTO SEGUROS
                        </th>
                    </tr>
                </ thead >
            </ Table >
        </div>
        <ResponsiveContainer width="100%" height="80%">
            <BarChart
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid />
                <XAxis dataKey="name" />
                <YAxis type="number" domain={[0, maximoNumero]} tickFormatter={tick => {
                    return tick.toLocaleString();
                }} />
                <Tooltip />
                <Legend  />
                <Bar dataKey="Actual" stackId="a" fill="#E46C0A" >
                    <LabelList dataKey="Actual" fontSize="16" fill="white" content={<CustomizedLabel external="external" />} />
                </Bar>
                <Bar dataKey="FPI" stackId="b" fill="#0C6F8F" label={<CustomizedLabel />}>
                    <LabelList dataKey="FPI" fontSize="16" fill="white" content={<CustomizedLabel external="external" />} />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    </>
    )
}
export default GraficoComportamientoSeguros;