import "../../css/clientes.css";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { AlertDescription } from "../alerts/AlertDescription"
import PaginationControl from "../menu/paginationControl"
import ModalNuevoUsuario from "./modalNuevoUsuario";
import ModalEditaUsuario from "./modalEditaUsuario";
import apiInstance from '../../services/apiConnection';
import { USUARIOS_ENDPOINTS } from "../../services/apiEndPoints"
import React from 'react';
import { useEffect, useState, useContext, useRef } from "react";
import { GlobalDataContext } from "../context/globalData"
import imgNuevocliente from "../../img/agregar-usuario.png";
import imgEliminar from "../../img/eliminar.png";
import eliminarRegistro from "../alerts/eliminar";

function UsuariosDetalle(props) {

    const ImgAsc = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-arrow-down-circle" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
            </svg>
        );
    }

    const gdc = useContext(GlobalDataContext);
    const [listaUsuarios, setListaUsuarios] = useState();
    const [modalNuevoUsuario, setModalNuevoUsuario] = useState(false);
    const modalNuevoUsuarioClose = () => setModalNuevoUsuario(false);
    const modalNuevoUsuarioShow = () => setModalNuevoUsuario(true);
    const usuarioEdita = useRef();
    const [modalEditaUsuario, setModalEditaUsuario] = useState(false);
    const modalEditaUsuarioClose = () => setModalEditaUsuario(false);
    const modalEditaUsuarioShow = (id) => {
        usuarioEdita.current = listaUsuarios[id];
        setModalEditaUsuario(true);
    }
    const [page, setPage] = useState(1);

    const listarUsuarios = (loggedUser) => {
        const headers = {
            "Authorization": "Bearer " + loggedUser.token
        };
        apiInstance.get(USUARIOS_ENDPOINTS.LISTARUSUARIOS, { headers: headers })
            .then((res) => {
                let data = res.data;
                setListaUsuarios(data);
            })
            .catch((error) => {
                AlertDescription("Ups!", "No se pudo cargar la lista de usuarios", "error");
            });
    }

    useEffect(() => {
        listarUsuarios(gdc.loggedUser)
        gdc.setShowMenu(true);
        gdc.comprobarSesion();
        document.body.style.backgroundColor = '#E5E5E5';
    }, [gdc])

    return (
        <div className="fondoGris">
            <div className="tituloClientes">
                Usuarios
            </div>
            <div className="tituloClientesBoton">
                <Button className="btn-outline-fpi" onClick={modalNuevoUsuarioShow }>
                    <img className="nuevoClienteButtonIco" src={imgNuevocliente} alt="FPI" />
                    Nuevo usuario
                </Button>
            </div>
            <hr />
            <div className="contenedorTablaClientes">
                <Table striped bordered hover
                    size="sm"
                    responsive="sm"
                >
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Email</th>
                            <th>Nombre <ImgAsc /></th>
                            <th>Referidor</th>
                            <th>Teléfono</th>
                            <th>Tipo usuario</th>
                            <th>Estado <ImgAsc /></th>
                            <th>Eliminar</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listaUsuarios && listaUsuarios.map((data, index) => {
                            if (index >= (page * gdc.rows - gdc.rows) && index < (page * gdc.rows)) {
                                return (
                                    <tr key={"reg-" + index}>
                                        <td onClick={() => { modalEditaUsuarioShow(index); }}>{index + 1}</td>
                                        <td onClick={() => { modalEditaUsuarioShow(index); }}>{data.email}</td>
                                        <td onClick={() => { modalEditaUsuarioShow(index); }}>{data.nombre}</td>
                                        <td onClick={() => { modalEditaUsuarioShow(index); }}>{data.referidor}</td>
                                        <td onClick={() => { modalEditaUsuarioShow(index); }}>{data.telefono}</td>
                                        <td onClick={() => { modalEditaUsuarioShow(index); }}>{data.userType}</td>
                                        <td onClick={() => { modalEditaUsuarioShow(index); }}>{data.activo === 1 ? "Activo" : "Inactivo"}</td>
                                        <td align="center">
                                            <Button className="btn-outline-fpi-eliminar" onClick={(e) => { eliminarRegistro(e, gdc.loggedUser, "usuario", { "email": data.email }, listarUsuarios) }}>
                                                <img className="eliminarButtonIco" src={imgEliminar} alt="FPI" />
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            } else {return null}
                        })}
                    </tbody>
                </Table>
            </div>
            <hr />
            <div className="listaClientesPaginationControl">
                {listaUsuarios && <PaginationControl rows={gdc.rows} page={page} setPage={setPage} setRows={gdc.setRows} maxPage={Math.ceil(listaUsuarios.length / gdc.rows)} />}
            </div>
            {modalNuevoUsuario && <ModalNuevoUsuario
                modalNuevoUsuario={modalNuevoUsuario}
                modalNuevoUsuarioClose={modalNuevoUsuarioClose}
                modalNuevoUsuarioShow={modalNuevoUsuarioShow}
                listarUsuarios={listarUsuarios}
            />}

            {modalEditaUsuario && <ModalEditaUsuario
                modalEditaUsuario={modalEditaUsuario}
                modalEditaUsuarioClose={modalEditaUsuarioClose}
                modalEditaUsuarioShow={modalEditaUsuarioShow}
                listarUsuarios={listarUsuarios}
                data={usuarioEdita.current}
            />}
        </div>
    )
}
export default UsuariosDetalle;