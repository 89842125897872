import { CLIENTES_ENDPOINTS, CREDITOS_ENDPOINTS, USUARIOS_ENDPOINTS } from "../../services/apiEndPoints"
import Swal from "sweetalert2";
import { AlertDescription } from "./AlertDescription";
import apiInstance from "../../services/apiConnection";

function eliminarRegistro(e, loggedUser, tabla, body, actualizar) {
    e.preventDefault();
    Swal.fire({
        title: '¿Está seguro de eliminar el registro?',
        text: "Una vez eliminado el registro no podrá revertirlo",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F5811E',
        cancelButtonColor: '#6C757D',
        confirmButtonText: 'Sí, borrar registro!'
    }).then((result) => {
        if (result.isConfirmed) {
            let endpoint;
            switch (tabla) {
                case "cliente":
                    endpoint = CLIENTES_ENDPOINTS;
                    break;
                case "credito":
                    endpoint = CREDITOS_ENDPOINTS;
                    break;
                case "usuario":
                    endpoint = USUARIOS_ENDPOINTS;
                    break;
                default:
                    AlertDescription("Upps!", "La tabla no existe", "error");
                    return false;
            }
            const headers = {
                "Authorization": "Bearer " + loggedUser.token,
            };
            apiInstance.delete(endpoint.ELIMINAR, { data: body, headers: headers })
                .then((res) => {
                    AlertDescription("Correcto", "Registro eliminado corréctamente", "success");
                    actualizar(loggedUser);
                })
                .catch((error) => {
                    AlertDescription("Ups!", "No se pudo eliminar el registro. Contacte al adminisrador.", "error");
                });
        }
    })
}
export default eliminarRegistro;