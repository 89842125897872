import React from 'react';
import Table from 'react-bootstrap/Table';

function TablaComportamientoDeuda(props) {

    const anios = [5, 8, 10]

    function calculoValores(anios) {
        if (props.estudio) {
            return (<>
                {
                    anios.map((anio, id) => {return(
                        <tbody key={"body" + id}>
                            <tr key={"estado" + id }>
                                <td>{anio} Años</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla0.SaldoFinalPesos[anio  * 12-1] ? props.estudio.tabla0.SaldoFinalPesos[anio * 12-1] : 0).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla1.SaldoFinalPesos[anio * 12-1] ? props.estudio.tabla1.SaldoFinalPesos[anio * 12-1] : 0).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla2.SaldoFinalPesos[anio * 12-1] ? props.estudio.tabla2.SaldoFinalPesos[anio * 12-1] : 0).toLocaleString()}</td>
                                <td align="right" colSpan={2}>${Math.round(props.estudio.tabla3.SaldoFinalPesos[anio * 12-1] ? props.estudio.tabla3.SaldoFinalPesos[anio * 12-1] : 0).toLocaleString()}</td>
                            </tr>
                            <tr key={"ganancia" + id}>
                                <td colSpan={3}><div className="propuestaDetalleNegrita" >Ganancia</div></td>
                                <td align="right" colSpan={2}>
                                    <div className="propuestaDetalleNegrita" >
                                    ${Math.round(props.estudio.tabla1.SaldoFinalPesos[anio * 12-1] ? props.estudio.tabla0.SaldoFinalPesos[anio * 12-1] - props.estudio.tabla1.SaldoFinalPesos[anio * 12-1] : 0).toLocaleString()}
                                </div>
                                </td>
                                <td align="right" colSpan={2}>
                                    <div className="propuestaDetalleNegrita" >
                                        ${Math.round(props.estudio.tabla2.SaldoFinalPesos[anio * 12 - 1] ? props.estudio.tabla0.SaldoFinalPesos[anio * 12 - 1] - props.estudio.tabla2.SaldoFinalPesos[anio * 12 - 1] : 0).toLocaleString()}
                                    </div>
                                </td>
                                <td align="right" colSpan={2}>
                                    <div className="propuestaDetalleNegrita" >
                                        ${Math.round(props.estudio.tabla3.SaldoFinalPesos[anio * 12 - 1] ? props.estudio.tabla0.SaldoFinalPesos[anio * 12 - 1] - props.estudio.tabla3.SaldoFinalPesos[anio * 12 - 1] : 0).toLocaleString()}
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    )}
                    )
                }
            </>
            )
        }
    }
    
    function mostrarTabla() {
        if (props.estudio && props.estudio.plazo > 0) {
            return (
                <div>
                    <Table className="table table-bordered border-dark"
                        size="sm"
                        responsive="sm"
                    >
                        <thead >
                            <tr className="text-center">
                                <th colSpan={9}>
                                    COMPORTAMIENTO DE LA DEUDA
                                </th>
                            </tr>
                            <tr className="text-center">
                                <th>
                                    Condiciones
                                </th>
                                <th colSpan={2}>
                                    Banco actual
                                </th>
                                <th colSpan={2}>
                                    Propuesta 1
                                </th>
                                <th colSpan={2}>
                                    Propuesta 2
                                </th>
                                <th colSpan={2}>
                                    Propuesta 3
                                </th>
                            </tr>
                        </thead>
                            {calculoValores(anios)}
                    </Table>
                </div>
            )
        }
    }

    return (
        <>
            {mostrarTabla()}
        </>
    );

}
export default TablaComportamientoDeuda;
