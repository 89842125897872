import React from 'react';
import { useState, useContext } from "react";
import { GlobalDataContext } from "../context/globalData"
import apiInstance from '../../services/apiConnection';
import { PROPUESTAS_ENDPOINTS } from "../../services/apiEndPoints"
import { AlertDescription } from "../alerts/AlertDescription"
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import fpilogoletras from "../../img/fpi-2-2-18-Dark.png";
import ModalSpinner from '../alerts/modalSpinner';

function ModalDetallePropuestas(props) {

    const gdc = useContext(GlobalDataContext);
    const [plazo1, setPlazo1] = useState(props.estudio[props.estudioId].plazo1);
    const [plazo2, setPlazo2] = useState(props.estudio[props.estudioId].plazo2);
    const [plazo3, setPlazo3] = useState(props.estudio[props.estudioId].plazo3);
    const [tasa1, setTasa1] = useState(props.estudio[props.estudioId].tasa1);
    const [tasa2, setTasa2] = useState(props.estudio[props.estudioId].tasa2);
    const [tasa3, setTasa3] = useState(props.estudio[props.estudioId].tasa3);
    const [seguro1, setSeguro1] = useState(props.estudio[props.estudioId].seguro1);
    const [seguro2, setSeguro2] = useState(props.estudio[props.estudioId].seguro2);
    const [seguro3, setSeguro3] = useState(props.estudio[props.estudioId].seguro3);
    const [showModalSpinner, setShowModalSpinner] = useState(false);

    function guardarPropuestas(e) {
        e.preventDefault();
        setShowModalSpinner(true);
        const headers = {
            "Authorization": "Bearer " + gdc.loggedUser.token,
        };
        const body = {
            idpropuesta: props.estudio[props.estudioId].idpropuesta,
            idcredito: props.estudio[props.estudioId].idcredito,
            cedula: props.estudio[props.estudioId].cedula,
            plazo1: plazo1 ? plazo1 : 0,
            tasa1: tasa1 ? tasa1 : 0,
            seguro1: seguro1 ? seguro1 : 0,
            plazo2: plazo2 ? plazo2 : 0,
            tasa2: tasa2 ? tasa2 : 0,
            seguro2: seguro2 ? seguro2 : 0,
            plazo3: plazo3 ? plazo3 : 0,
            tasa3: tasa3 ? tasa3 : 0,
            seguro3: seguro3 ? seguro3 : 0,
        }
        apiInstance.patch(PROPUESTAS_ENDPOINTS.EDITAR, body, { headers: headers })
            .then((res) => {
                setShowModalSpinner(false);
                AlertDescription("Correcto", "Datos guardados corréctamente", "success");
                gdc.actualizar();
                props.modalDetallePropuestasClose();

            })
            .catch((error) => {
                setShowModalSpinner(false);
                AlertDescription("Ups!", "No se pudo guardar el registro. Contacte al adminisrador.", "error");
            });
    }

    return (
        <div>
            <Modal
                size="lg"
                show={props.modalDetallePropuestas}
                onHide={props.modalDetallePropuestasClose}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header closeButton className="ModalDetalleClienteHeader">
                    <Modal.Title >
                        <span> | </span>
                        <img className="login-fpi-logo-letras-ModalDetalleClienteHeader" src={fpilogoletras} alt="FPI" />
                        <span> | </span>
                        Editar Propuestas
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={guardarPropuestas}>
                    <Modal.Body className="ModalDetalleClienteBody">
                        <Table className="table table-bordered border-dark"
                            size="sm"
                            responsive="sm"
                        >
                            <thead className="clienteDetalleFondoTitulo">
                                <tr className="text-center">
                                    <th colSpan={5}>
                                        OPCIONES DE NEGOCIACIÓN
                                    </th>
                                </tr>
                                <tr className="text-center">
                                    <th>
                                        Condiciones
                                    </th>
                                    <th>
                                        Propuesta 1
                                    </th>
                                    <th>
                                        Propuesta 2
                                    </th>
                                    <th>
                                        Propuesta 3
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Plazo</td>
                                    <td>
                                        <input id="plazo1"
                                            className="inputboxclienteDetalle"
                                            type="number"
                                            placeholder="Plazo 1"
                                            value={plazo1}
                                            maxLength="15"
                                            onChange={(e) => setPlazo1(e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input id="plazo2"
                                            className="inputboxclienteDetalle"
                                            type="number"
                                            placeholder="Plazo 2"
                                            value={plazo2}
                                            maxLength="15"
                                            onChange={(e) => setPlazo2(e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input id="plazo3"
                                            className="inputboxclienteDetalle"
                                            type="number"
                                            placeholder="Plazo 3"
                                            value={plazo3}
                                            maxLength="15"
                                            onChange={(e) => setPlazo3(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Tasa de interés</td>
                                    <td>
                                        <input id="tasa1"
                                            className="inputboxclienteDetalle"
                                            type="number"
                                            placeholder="Tasa de interes 1"
                                            value={tasa1}
                                            onChange={(e) => setTasa1(e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input id="tasa2"
                                            className="inputboxclienteDetalle"
                                            type="number"
                                            placeholder="Tasa de interes 2"
                                            value={tasa2}
                                            onChange={(e) => setTasa2(e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input id="tasa3"
                                            className="inputboxclienteDetalle"
                                            type="number"
                                            placeholder="Tasa de interes 3"
                                            value={tasa3}
                                            onChange={(e) => setTasa3(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Seguro</td>
                                    <td>
                                        <input id="seguro1"
                                            className="inputboxclienteDetalle"
                                            type="number"
                                            placeholder="Seguro 1"
                                            value={seguro1}
                                            maxLength="15"
                                            onChange={(e) => setSeguro1(e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input id="seguro2"
                                            className="inputboxclienteDetalle"
                                            type="number"
                                            placeholder="Seguro 2"
                                            value={seguro2}
                                            maxLength="15"
                                            onChange={(e) => setSeguro2(e.target.value)}
                                        />
                                    </td>
                                    <td>
                                        <input id="seguro3"
                                            className="inputboxclienteDetalle"
                                            type="number"
                                            placeholder="Seguro 3"
                                            value={seguro3}
                                            maxLength="15"
                                            onChange={(e) => setSeguro3(e.target.value)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer className="ModalDetalleClienteBody">
                        <Button type="button" variant="secondary" onClick={props.modalDetallePropuestasClose}>
                            Cancelar
                        </Button>
                        <Button className="btn-modal-guardar" type="submit">
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ModalSpinner showModalSpinner={showModalSpinner }/>
        </div>
    );
}
export default ModalDetallePropuestas;