import React from 'react';
import { useState, useContext, useEffect } from "react";
import { GlobalDataContext } from "../context/globalData"
import apiInstance from '../../services/apiConnection';
import { USUARIOS_ENDPOINTS } from "../../services/apiEndPoints"
import { AlertDescription } from "../alerts/AlertDescription"
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import fpilogoletras from "../../img/fpi-2-2-18-Dark.png";
import ModalSpinner from '../alerts/modalSpinner';

function ModalMiCuenta(props) {

    const [showModalSpinner, setShowModalSpinner] = useState(false);

    const getMiUsuario = (loggedUser) => {
        const headers = {
            "Authorization": "Bearer " + loggedUser.token
        };
        setShowModalSpinner(true);
        apiInstance.get(USUARIOS_ENDPOINTS.MIUSUARIO, { headers: headers })
            .then((res) => {
                setShowModalSpinner(false);
                let data = res.data;
                data[0].password = ""
                setMiUsuario(data[0]);
                setNombre(data[0].nombre)
                setTelefono(data[0].telefono)
            })
            .catch((error) => {
                setShowModalSpinner(false);
                AlertDescription("Ups!", "No se pudo cargar la información del usuario", "error");
            });
    }

    const gdc = useContext(GlobalDataContext);
    const [miUsuario, setMiUsuario] = useState();
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [nombre, setNombre] = useState("");
    const [telefono, setTelefono] = useState("");

    useEffect(() => {
        getMiUsuario(gdc.loggedUser)
    }, [gdc])

        
    function editarUsuario(e) {
        e.preventDefault();
        setShowModalSpinner(true);
        if (password === password2) {
            const headers = {
                "Authorization": "Bearer " + gdc.loggedUser.token,
            };
            const body = {
                email: miUsuario.email,
                password: password,
                userType: miUsuario.userType,
                nombre: nombre,
                telefono: telefono,
                referidor: miUsuario.referidor,
                activo: miUsuario.activo
            }
            apiInstance.patch(USUARIOS_ENDPOINTS.EDITAR, body, { headers: headers })
                .then((res) => {
                    setShowModalSpinner(false);
                    AlertDescription("Correcto", "Usuario modificado corréctamente", "success");
                    props.modalMiCuentaClose();
                })
                .catch((error) => {
                    setShowModalSpinner(false);
                    AlertDescription("Ups!", "No se pudo guardar el registro. Contacte al adminisrador.", "error");
                });
        } else {
            setShowModalSpinner(false);
            AlertDescription("Ups!", "El password no coincide.", "error");
        }
    }
            
    return (
        <div>
            <Modal
                show={props.modalMiCuenta}
                onHide={props.modalMiCuentaClose}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header closeButton className="ModalDetalleClienteHeader">
                    <Modal.Title >
                        <span> | </span>
                        <img className="login-fpi-logo-letras-ModalDetalleClienteHeader" src={fpilogoletras} alt="FPI" />
                        <span> | </span>
                        Mi Cuenta
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={editarUsuario}>
                    <Modal.Body className="ModalDetalleClienteBody">
                        <Table className="table table-bordered border-dark" size="sm">
                            <thead className="clienteDetalleFondoTitulo">
                                <tr className="text-center">
                                    <th colSpan={2}>
                                        MIS DATOS
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Email</td>
                                    <td>
                                        {miUsuario && miUsuario.email}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Nombre</td>
                                    <td>
                                        <input id="nombre"
                                            className="inputboxclienteDetalle"
                                            type="text"
                                            placeholder="Nombre"
                                            required
                                            defaultValue={nombre}
                                            maxLength="45"
                                            onChange={(e) => setNombre((e.target.value).toUpperCase())}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Password</td>
                                    <td>
                                        <input id="password"
                                            className="inputboxclienteDetalle"
                                            type="password"
                                            placeholder="Password"
                                            minLength="4"
                                            maxLength="45"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Confirma Password</td>
                                    <td>
                                        <input id="password2"
                                            className="inputboxclienteDetalle"
                                            type="password"
                                            placeholder="Confirma Password"
                                            minLength="4"
                                            maxLength="45"
                                            onChange={(e) => setPassword2(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Teléfono</td>
                                    <td>
                                        <input id="telefono"
                                            className="inputboxclienteDetalle"
                                            type="tel"
                                            placeholder="Telefono"
                                            defaultValue={miUsuario && miUsuario.telefono}
                                            maxLength="10"
                                            onChange={(e) => setTelefono(e.target.value)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer className="ModalDetalleClienteBody">
                        <Button type="button" variant="secondary" onClick={props.modalMiCuentaClose}>
                            Cancelar
                        </Button>
                        <Button className="btn-modal-guardar" type="submit">
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ModalSpinner showModalSpinner={showModalSpinner} />
        </div>
    );
}
export default ModalMiCuenta;