import ClienteDetalle from "../components/clientes/clienteDetalle";
import LoginForm from "../components/login/loginForm";
import { GlobalDataContext } from "../components/context/globalData"
import React from 'react';
import { useContext } from "react";


function Cliente(props) {

    const gdc = useContext(GlobalDataContext);
    gdc.comprobarSesion();


    return (
        <div>
            {gdc.miLogin === false && <LoginForm />}
            {gdc.miLogin && <ClienteDetalle />}

        </div>
    )
}

export default Cliente;