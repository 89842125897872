import LoginForm from "../components/login/loginForm";
import SeguimientoClientesDetalle from "../components/seguimiento/seguimientoClientesDetalle";
import React from 'react';
import { useContext } from "react";
import { GlobalDataContext } from "../components/context/globalData"
import Error404 from "./error404";


function SeguimientoClientes(props) {

    const gdc = useContext(GlobalDataContext);

    return (
        <div >
            {gdc.miLogin === false && <LoginForm />}
            {gdc.miLogin && gdc.loggedUser.type === "admin" && <SeguimientoClientesDetalle />}
            {gdc.miLogin && gdc.loggedUser.type !== "admin" && <Error404 />}
        </div>
    );
}

export default SeguimientoClientes;