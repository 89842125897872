import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GlobalDataContextProvider from "./components/context/globalData"
import Menu from "./components/menu/menu"
import Dashboard from "./routes/dashboard";
import Cliente from "./routes/cliente";
import Propuesta from "./routes/propuesta";
import SeguimientoClientes from './routes/seguimientoClientes';
import Usuarios from "./routes/usuarios";
import Error404 from './routes/error404';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <GlobalDataContextProvider>
                <>
                    <Menu />
                    <Routes>
                        <Route path="/">
                            <Route index element={<Dashboard />} />
                        </Route>
                        <Route exact path="lista-clientes" element={<Dashboard />} />
                        <Route exact path="cliente" element={<Cliente />} />
                        <Route exact path="propuesta">
                            <Route exact path=":id" element={<Propuesta />} /> 
                        </Route>
                        <Route exact path="seguimiento" element={<SeguimientoClientes />} />
                        <Route exact path="usuarios" element={<Usuarios />} />
                        <Route path="*" element={<Error404 />} />
                    </Routes>
                </>
            </GlobalDataContextProvider>
        </BrowserRouter>
    </React.StrictMode>
);
