import React from 'react';
import Table from 'react-bootstrap/Table';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';

function GraficoDistribucionCuota(props) {

    let data;
    if (props.estudio) {
        data = [
            {
                name: 'Actual',
                Capital: Math.round(props.estudio.tabla0.ParticipacionCapital[0]),
                Interes: Math.round(props.estudio.tabla0.ParticipacionInteres[0]),
                
            },
            {
                name: 'Prop 1',
                Capital: Math.round(props.estudio.tabla1.ParticipacionCapital[0]),
                Interes: Math.round(props.estudio.tabla1.ParticipacionInteres[0]),
                
            },
            {
                name: 'Prop 2',
                Capital: Math.round(props.estudio.tabla2.ParticipacionCapital[0]),
                Interes: Math.round(props.estudio.tabla2.ParticipacionInteres[0]),
                
            },
            {
                name: 'Prop 3',
                Capital: Math.round(props.estudio.tabla3.ParticipacionCapital[0]),
                Interes: Math.round(props.estudio.tabla3.ParticipacionInteres[0]),
                
            },
        ];
    } 
    
    return (<>
        <div>
            <Table className="table table-bordered border-dark"
                size="sm"
                responsive="sm"
            >
                <thead >
                    <tr className="text-center">
                        <th colSpan={9}>
                            DISTRIBUCIÓN DE LA CUOTA
                        </th>
                    </tr>
                </ thead >
            </ Table >
        </div>
        <ResponsiveContainer width="100%" height="80%">
            <BarChart
                data={data}
                margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend layout="vertical" align="left" verticalAlign="middle" />
                <Bar dataKey="Interes" stackId="a" fill="#E46C0A" label={{ fill: 'white', fontSize: 18 }} />
                <Bar dataKey="Capital" stackId="a" fill="#0C6F8F" >
                    <LabelList dataKey="Capital" valueAccessor={(data) => data.bar1} fontSize="18" fill="white" />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    </>
    )
}
export default GraficoDistribucionCuota;