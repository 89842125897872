import "../../css/clienteDetalle.css";
import listarPropuestas from "../../services/listarPropuestas"
import TablaDetalleCliente from "./tablaDetalleCliente";
import AcordionEstudioCredito from "./acordionEstudioCredito";
import ModalDetalleCredito from "./modalDetalleCredito"
import ModalDetallePropuestas from "./modalDetallePropuestas"
import ModalNuevoEstudio from "./modalNuevoEstudio"
import Button from 'react-bootstrap/Button';
import imgNuevoEstudio from "../../img/agregar-estudio.png";
import React from 'react';
import { useEffect, useState, useContext } from "react";
import { GlobalDataContext } from "../context/globalData"

function ClienteDetalle(props) {

    const gdc = useContext(GlobalDataContext);
    const [estudio, setEstudio] = useState([]);
    const [estudioId, setEstudioId] = useState();
    const [modalDetalleCredito, setModalDetalleCredito] = useState(false);
    const modalDetalleCreditoClose = () => setModalDetalleCredito(false);
    const modalDetalleCreditoShow = (idestudio) => {
        setModalDetalleCredito(true);
        setEstudioId(idestudio);
    }
    const [modalDetallePropuestas, setModalDetallePropuestas] = useState(false);
    const modalDetallePropuestasClose = () => setModalDetallePropuestas(false);
    const modalDetallePropuestasShow = (idestudio) => {
        setModalDetallePropuestas(true);
        setEstudioId(idestudio);
    }
    const [modalNuevoEstudio, setModalNuevoEstudio] = useState(false);
    const modalNuevoEstudioClose = () => setModalNuevoEstudio(false);
    const modalNuevoEstudioShow = () => setModalNuevoEstudio(true);

    useEffect(() => {
        listarPropuestas(gdc.loggedUser, gdc.cliente.cedula, setEstudio);
        gdc.setShowMenu(true);
        document.body.style.backgroundColor = '#E5E5E5';
    }, [gdc])

    return (
        <div className="fondoGris">
                <div className="tituloClientes">
                Estudio de Crédito
                </div>
            <div className="tituloClienteDetalleBoton">
                <Button className="btn-outline-fpi" onClick={modalNuevoEstudioShow }>
                    <img className="nuevoEstudioButtonIco" src={imgNuevoEstudio} alt="FPI" />
                        Nuevo estudio
                    </Button>
                </div>
                <hr />
            <div className="clienteDetalleTablaCliente">
                <TablaDetalleCliente />
            </div>
            <div className="clienteDetalleAcordionEstudioCredito">
                <AcordionEstudioCredito estudio={estudio} modalDetalleCreditoShow={modalDetalleCreditoShow} modalDetallePropuestasShow={modalDetallePropuestasShow} />
            </div>
            {modalDetalleCredito && <ModalDetalleCredito
                modalDetalleCredito={modalDetalleCredito}
                modalDetalleCreditoClose={modalDetalleCreditoClose}
                modalDetalleCreditoShow={modalDetalleCreditoShow}
                estudio={estudio}
                setEstudio={setEstudio}
                estudioId={estudioId }
            />}
            {modalDetallePropuestas && gdc.loggedUser.type==="admin" && <ModalDetallePropuestas
                modalDetallePropuestas={modalDetallePropuestas}
                modalDetallePropuestasClose={modalDetallePropuestasClose}
                modalDetallePropuestasShow={modalDetallePropuestasShow}
                estudio={estudio}
                setEstudio={setEstudio}
                estudioId={estudioId}
            />}
            {modalNuevoEstudio && gdc.loggedUser.type !== "client" && <ModalNuevoEstudio
                modalNuevoEstudio={modalNuevoEstudio}
                modalNuevoEstudioClose={modalNuevoEstudioClose}
                modalNuevoEstudioShow={modalNuevoEstudioShow}
            />}
        </div>
    )
}
export default ClienteDetalle;