import "../../css/clientes.css";
import Table from 'react-bootstrap/Table';
import { AlertDescription } from "../alerts/AlertDescription"
import PaginationControl from "../menu/paginationControl"
import apiInstance from '../../services/apiConnection';
import { PROPUESTAS_ENDPOINTS } from "../../services/apiEndPoints"
import { useNavigate, NavLink } from "react-router-dom";
import React from 'react';
import { useEffect, useState, useContext } from "react";
import { GlobalDataContext } from "../context/globalData"
import ModalActualizaSeguimiento from "./modalActualizaSeguimiento";

function SeguimientoClientesDetalle(props) {

    const ImgAsc = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-arrow-down-circle" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z" />
            </svg>
        );
    }
    const ImgDes = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-arrow-up-circle" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
            </svg>
        );
    }
    const navigate = useNavigate();
    const gdc = useContext(GlobalDataContext);
    const [listaClientes, setListaClientes] = useState();
    const [columnaOrden, setColumnaOrden] = useState();
    const [orden, setOrden] = useState();
    const [idEdita, setIdEdita] = useState();
    const [modalActualizaSeguimiento, setModalActualizaSeguimiento] = useState(false);
    const modalActualizaSeguimientoClose = () => setModalActualizaSeguimiento(false);
    const modalActualizaSeguimientoShow = (idEdita) => {
        setIdEdita(idEdita);
        setModalActualizaSeguimiento(true);
        listarClientes(gdc.loggedUser);
    }
    const [page, setPage] = useState(1);

    const listarClientes = (loggedUser, col, ord) => {
        const headers = {
            "Authorization": "Bearer " + loggedUser.token,
            "columna": col,
            "orden": ord
        };
        apiInstance.get(PROPUESTAS_ENDPOINTS.SEGUIMIENTOCLEINTE, { headers: headers })
            .then((res) => {
                let data = res.data;
                setListaClientes(data);
            })
            .catch((error) => {
                AlertDescription("Ups!", "No se pudo cargar la lista declientes", "error");
            });
    }

    useEffect(() => {
        listarClientes(gdc.loggedUser, "", "");
        gdc.setShowMenu(true);
        gdc.comprobarSesion();
        document.body.style.backgroundColor = '#E5E5E5';
    }, [gdc])

    function ordenar(columnaOrdenar) {
        if (columnaOrdenar === "No") {
            let ord = "ASC";
            setOrden(ord);
            setColumnaOrden(columnaOrdenar);
            listarClientes(gdc.loggedUser, "", ord);
        }else if (columnaOrden === columnaOrdenar) {
            let ord = orden === "ASC" ? "DESC" : "ASC"
            setOrden(ord);
            listarClientes(gdc.loggedUser, columnaOrdenar, ord);
        } else {
            setColumnaOrden(columnaOrdenar);
            setOrden("ASC");
            listarClientes(gdc.loggedUser, columnaOrdenar, "ASC");
        }
    }

    function ColumnaTitulo(columna, titulo) {
        if (columnaOrden === columna) {
            return (
                <th id="seguimientoClientesEcabezadoColumnaOrden" onClick={() => { ordenar(columna) }}>{titulo} {orden === "ASC" ? <ImgAsc /> : <ImgDes />}</th>
            )
        } else {
            return (
                <th className="seguimientoClientesCeldaBoton" onClick={() => { ordenar(columna) }}>{titulo}</th>
                )
        }
    }

    return (
        <div className="fondoGris">
            <div className="tituloClientes">
                Seguimiento Clientes
            </div>
            <div className="contenedorTablaSegumientoClientes">
                <Table striped bordered hover
                    size="lg"
                >
                    <thead className="encabezadoFlotante">
                        <tr align={"center"} >
                            <th colSpan={8} id="seguimientoClientesEcabezadoDatosCliente">DATOS CLIENTE</th>
                            <th colSpan={7} id="seguimientoClientesEcabezadoDatosCredito">DATOS CREDITO</th>
                            <th colSpan={9} id="seguimientoClientesEcabezadoDatosRelacionFPI">RELACIÓN FPI</th>
                        </tr>
                        <tr align={"center"}>
                            {ColumnaTitulo("No", "No.") }
                            {ColumnaTitulo("nombre", "Nombre")}
                            {ColumnaTitulo("cedula", "Cédula")}
                            {ColumnaTitulo("fechanac", "Fecha Nac.")}
                            {ColumnaTitulo("telefono1", "Teléfono 1")}
                            {ColumnaTitulo("telefono2", "Teléfono 2")}
                            {ColumnaTitulo("email", "Email")}
                            {ColumnaTitulo("ingresos", "Ingresos Mes")}
                            {ColumnaTitulo("banco", "Banco")}
                            {ColumnaTitulo("tipo", "Tipo Crédito")}
                            {ColumnaTitulo("valorComercial", "Valor Inmueble")}                           
                            {ColumnaTitulo("valorCredito", "Saldo Crédito")}                           
                            {ColumnaTitulo("interes", "Interés")}
                            {ColumnaTitulo("plazo", "Plazo")}
                            {ColumnaTitulo("valorContrato", "Honorarios Pactados")}
                            {ColumnaTitulo("asesor", "Asesor")}
                            {ColumnaTitulo("referidor", "Referidor")}
                            {ColumnaTitulo("ciudad", "Origen")}
                            {ColumnaTitulo("fechaCreacion", "Fecha Solicitud")}
                            {columnaOrden ? ColumnaTitulo("estado", "Estado") :
                                <th >Estado <ImgAsc /></th>}
                            {columnaOrden ? ColumnaTitulo("fechaEstado", "Fecha Estado") :
                                <th >Fecha Estado <ImgDes /></th>}
                            {ColumnaTitulo("observaciones", "Observaciones")}
                            {ColumnaTitulo("telefonoAsesor", "Teléfono Asesor")}
                            {ColumnaTitulo("emailAsesor", "Email Asesor")}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {listaClientes && listaClientes.map((data, index) => {
                            if (index >= (page * gdc.rows - gdc.rows) && index < (page * gdc.rows)) {
                                return (
                                    <tr key={index}
                                    >
                                        <td >{index + 1}</td>
                                        <td className="seguimientoClientesCeldaBoton" onClick={() => {
                                            gdc.setCliente(data);
                                            navigate("/cliente");
                                        }}><NavLink className="seguimientoClientesLink">
                                                <div className="seguimientoClientesDivNombre">
                                                    {data.nombre} {data.apellido}
                                                </div>
                                            </NavLink></td>
                                        <td className="seguimientoClientesCeldaBoton" onClick={() => {
                                            gdc.setCliente(data);
                                            navigate("/cliente");
                                        }}><NavLink className="seguimientoClientesLink">{data.cedula}</NavLink></td>
                                        <td>{new Date(data.fechanac).toLocaleDateString()}</td>
                                        <td>{data.telefono1}</td>
                                        <td>{data.telefono2}</td>
                                        <td>{data.email}</td>
                                        <td>${Math.round(data.ingresos).toLocaleString()}</td>
                                        <td>{data.banco}</td>
                                        <td><div className="seguimientoClientesDivTipoCredito">{data.tipo}</div></td>
                                        <td align="right">${Math.round(data.valorComercial).toLocaleString()}</td>
                                        <td align="right">${Math.round(data.valorCredito).toLocaleString()}</td>
                                        <td align="right">{data.interes}%</td>
                                        <td align="center">{data.plazo}</td>
                                        <td className="seguimientoClientesCeldaBoton" align="right" onClick={() => { modalActualizaSeguimientoShow(index) }}>
                                            <NavLink className="seguimientoClientesLink">${Math.round(data.valorContrato).toLocaleString()}</NavLink>
                                        </td>
                                        <td >{data.nombreAsesor}</td>
                                        <td align="center">{data.referidor}</td>
                                        <td >{data.ciudad}</td>
                                        <td>{new Date(data.fechaCreacion).toLocaleDateString()}</td>
                                        <td className="seguimientoClientesCeldaBoton" onClick={() => { modalActualizaSeguimientoShow(index) }}><div className="seguimientoClientesDivEstado">
                                            <NavLink className="seguimientoClientesLink">{data.estado}</NavLink>
                                        </div></td>
                                        <td className="seguimientoClientesCeldaBoton" onClick={() => { modalActualizaSeguimientoShow(index) }}>
                                            <NavLink className="seguimientoClientesLink">{new Date(data.fechaEstado).toLocaleDateString()}</NavLink>
                                        </td>
                                        <td className="seguimientoClientesCeldaBoton" onClick={() => { modalActualizaSeguimientoShow(index) }}>
                                            <NavLink className="seguimientoClientesLink">{data.observaciones}</NavLink>
                                        </td>
                                        <td >{data.telefonoAsesor}</td>
                                        <td >{data.emailAsesor}</td>
                                    </tr>
                                )
                            } else {return null }
                        })}
                    </tbody>
                </Table>
            </div>
            <div className="listaClientesPaginationControl">
                {listaClientes && <PaginationControl rows={gdc.rows} page={page} setPage={setPage} setRows={gdc.setRows} maxPage={Math.ceil(listaClientes.length / gdc.rows)} />}
            </div>
            {modalActualizaSeguimiento && <ModalActualizaSeguimiento
                modalActualizaSeguimiento={modalActualizaSeguimiento}
                modalActualizaSeguimientoClose={modalActualizaSeguimientoClose}
                data={listaClientes[idEdita]}
                listarClientes={listarClientes}
            />}
        </div>
    )
}
export default SeguimientoClientesDetalle;