import React from 'react';
import { useState, useContext, useEffect } from "react";
import { GlobalDataContext } from "../context/globalData"
import apiInstance from '../../services/apiConnection';
import { USUARIOS_ENDPOINTS } from "../../services/apiEndPoints"
import { AlertDescription } from "../alerts/AlertDescription"
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import fpilogoletras from "../../img/fpi-2-2-18-Dark.png";
import ModalSpinner from '../alerts/modalSpinner';

function ModalEditaUsuario(props) {

    const gdc = useContext(GlobalDataContext);
    const [password, setPassword] = useState("");
    const [password2, setPassword2] = useState("");
    const [nombre, setNombre] = useState("");
    const [userType, setUserType] = useState("");
    const [telefono, setTelefono] = useState("");
    const [referidor, setReferidor] = useState("");
    const [activo, setActivo] = useState(true);
    const [showModalSpinner, setShowModalSpinner] = useState(false);

    useEffect(() => {
        setNombre(props.data.nombre);
        setUserType(props.data.userType);
        setTelefono(props.data.telefono);
        setReferidor(props.data.referidor);
        setActivo(props.data.activo === 1 ? true : false);
    }, [props.data])

    function editarUsuario(e) {
        e.preventDefault();
        setShowModalSpinner(true);
        if (password === password2) {
            const headers = {
                "Authorization": "Bearer " + gdc.loggedUser.token,
            };
            const body = {
                email: props.data.email,
                password: password,
                userType: userType,
                nombre: nombre,
                telefono: telefono,
                referidor: referidor,
                activo: activo
            }
            apiInstance.patch(USUARIOS_ENDPOINTS.EDITAR, body, { headers: headers })
                .then((res) => {
                    setShowModalSpinner(false);
                    AlertDescription("Correcto", "Usuario modificado corréctamente", "success");
                    props.listarUsuarios(gdc.loggedUser);
                    props.modalEditaUsuarioClose();
                })
                .catch((error) => {
                    setShowModalSpinner(false);
                    AlertDescription("Ups!", "No se pudo guardar el registro. Contacte al adminisrador.", "error");
                });
        } else {
            setShowModalSpinner(false);
            AlertDescription("Ups!", "El password no coincide.", "error");
        }
    }

    return (
        <div>
            <Modal
                show={props.modalEditaUsuario}
                onHide={props.modalEditaUsuarioClose}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header closeButton className="ModalDetalleClienteHeader">
                    <Modal.Title >
                        <span> | </span>
                        <img className="login-fpi-logo-letras-ModalDetalleClienteHeader" src={fpilogoletras} alt="FPI" />
                        <span> | </span>
                        Edita Usuario
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={editarUsuario}>
                    <Modal.Body className="ModalDetalleClienteBody">
                        <Table className="table table-bordered border-dark" size="sm">
                            <thead className="clienteDetalleFondoTitulo">
                                <tr className="text-center">
                                    <th colSpan={2}>
                                        DATOS DEL USUARIO
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Tipo Usuario</td>
                                    <td>
                                        <Form.Select
                                            aria-label="Tipo de usuario"
                                            required
                                            defaultValue={props.data.userType}
                                            onChange={(e) => setUserType(e.target.value)}
                                        >
                                            <option value="asesor">Asesor</option>
                                            <option value="direct">Director</option>
                                            <option value="admin">Administrador</option>
                                        </Form.Select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>
                                        {props.data.email}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Nombre</td>
                                    <td>
                                        <input id="nombre"
                                            className="inputboxclienteDetalle"
                                            type="text"
                                            placeholder="Nombre"
                                            required
                                            defaultValue={nombre}
                                            maxLength="45"
                                            onChange={(e) => setNombre((e.target.value).toUpperCase())}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Password</td>
                                    <td>
                                        <input id="password"
                                            className="inputboxclienteDetalle"
                                            type="password"
                                            placeholder="Password"
                                            defaultValue={password}
                                            minLength="4"
                                            maxLength="45"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Confirma Password</td>
                                    <td>
                                        <input id="password2"
                                            className="inputboxclienteDetalle"
                                            type="password"
                                            placeholder="Confirma Password"
                                            defaultValue={password2}
                                            minLength="4"
                                            maxLength="45"
                                            onChange={(e) => setPassword2(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Referidor</td>
                                    <td>
                                        <input id="referidor"
                                            className="inputboxclienteDetalle"
                                            type="text"
                                            placeholder="Referidor"
                                            defaultValue={referidor}
                                            maxLength="60"
                                            onChange={(e) => setReferidor((e.target.value).toUpperCase())}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Teléfono</td>
                                    <td>
                                        <input id="telefono"
                                            className="inputboxclienteDetalle"
                                            type="tel"
                                            placeholder="Telefono"
                                            defaultValue={telefono}
                                            maxLength="10"
                                            onChange={(e) => setTelefono(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Estado
                                    </td>
                                    <td>
                                        <input id="estado"
                                            type="checkbox"
                                            name="estado"
                                            defaultChecked={props.data.activo === 1 ? true : false}
                                            onChange={(e) => setActivo(!activo)}
                                        />
                                        <label className="radio" htmlFor="estado">Activo</label>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer className="ModalDetalleClienteBody">
                        <Button type="button" variant="secondary" onClick={props.modalEditaUsuarioClose}>
                            Cancelar
                        </Button>
                        <Button className="btn-modal-guardar" type="submit">
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ModalSpinner showModalSpinner={showModalSpinner }/>
        </div>
    );
}
export default ModalEditaUsuario;