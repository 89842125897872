import React from 'react';
import { useState, useContext } from "react";
import { GlobalDataContext } from "../context/globalData"
import apiInstance from '../../services/apiConnection';
import { CLIENTES_ENDPOINTS } from "../../services/apiEndPoints"
import { AlertDescription } from "../alerts/AlertDescription"
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import fpilogoletras from "../../img/fpi-2-2-18-Dark.png";
import ModalSpinner from '../alerts/modalSpinner';

function ModalNuevoCliente(props) {

    const gdc = useContext(GlobalDataContext);
    const [cedula, setCedula] = useState("");
    const [nombre, setNombre] = useState("");
    const [apellido, setApellido] = useState("");
    const [fechanac, setFechanac] = useState("");
    const [ciudad, setCiudad] = useState("");
    const [telefono1, setTelefono1] = useState("");
    const [telefono2, setTelefono2] = useState("");
    const [email, setEmail] = useState("");
    const [showModalSpinner, setShowModalSpinner] = useState(false);

    function crearCliente(e) {
        e.preventDefault();
        setShowModalSpinner(true);
        const headers = {
            "Authorization": "Bearer " + gdc.loggedUser.token,
        };
        const body = {
            cedula: cedula,
            nombre: nombre,
            apellido: apellido,
            fechanac: fechanac,
            ciudad: ciudad,
            telefono1: telefono1,
            telefono2: telefono2 ? telefono2 : " ",
            email: email ? email : " ",
            asesor: gdc.loggedUser.email
        }
        apiInstance.post(CLIENTES_ENDPOINTS.CREAR, body, { headers: headers })
            .then((res) => {
                setShowModalSpinner(false);
                gdc.setCliente(body);
                AlertDescription("Correcto", "Cliente creado corréctamente", "success");
                props.listarClientes(gdc.loggedUser);
                props.modalNuevoClienteClose();

            })
            .catch((error) => {
                setShowModalSpinner(false);
                if (error.response.data.error.errno) {
                    error.response.data.error.errno === 1062 ?
                        AlertDescription("Ups!", "Ya existe un cliente registrado con esta cédula", "error") :
                        AlertDescription("Ups!", "No se pudo guardar el registro. Contacte al adminisrador.", "error");
                } else {
                    AlertDescription("Ups!", "No se pudo guardar el registro. Contacte al adminisrador.", "error");
                }
            });
    }

    return (
        <div>
            <Modal
                show={props.modalNuevoCliente}
                onHide={props.modalNuevoClienteClose}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header closeButton className="ModalDetalleClienteHeader">
                    <Modal.Title >
                        <span> | </span>
                        <img className="login-fpi-logo-letras-ModalDetalleClienteHeader" src={fpilogoletras} alt="FPI" />
                        <span> | </span>
                        Nuevo Cliente
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={crearCliente}>
                    <Modal.Body className="ModalDetalleClienteBody">
                        <Table className="table table-bordered border-dark" size="sm">
                            <thead className="clienteDetalleFondoTitulo">
                                <tr className="text-center">
                                    <th colSpan={2}>
                                        DATOS DEL CLIENTE
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cédula</td>
                                    <td>
                                        <input id="cedula"
                                            className="inputboxclienteDetalle"
                                            type="text"
                                            placeholder="Cedula"
                                            required
                                            defaultValue={cedula}
                                            maxLength="45"
                                            onChange={(e) => setCedula(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Nombre</td>
                                    <td>
                                        <input id="nombre"
                                            className="inputboxclienteDetalle"
                                            type="text"
                                            placeholder="Nombre"
                                            required
                                            defaultValue={nombre}
                                            maxLength="45"
                                            onChange={(e) => setNombre((e.target.value).toUpperCase())}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Apellido</td>
                                    <td>
                                        <input id="apellido"
                                            className="inputboxclienteDetalle"
                                            type="text"
                                            placeholder="Apellido"
                                            required
                                            defaultValue={apellido}
                                            maxLength="45"
                                            onChange={(e) => setApellido((e.target.value).toUpperCase())}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Fecha Nacimiento</td>
                                    <td>
                                        <input id="fechanac"
                                            className="inputboxclienteDetalle"
                                            type="date"
                                            placeholder="Fecha de nacimiento"
                                            required
                                            defaultValue={fechanac}
                                            onChange={(e) => setFechanac(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Ciudad</td>
                                    <td>
                                        <input id="ciudad"
                                            className="inputboxclienteDetalle"
                                            type="text"
                                            placeholder="Ciudad"
                                            required
                                            defaultValue={ciudad}
                                            maxLength="60"
                                            onChange={(e) => setCiudad((e.target.value).toUpperCase())}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Teléfono 1</td>
                                    <td>
                                        <input id="telefono1"
                                            className="inputboxclienteDetalle"
                                            type="tel"
                                            placeholder="Telefono 1"
                                            required
                                            defaultValue={telefono1}
                                            maxLength="10"
                                            onChange={(e) => setTelefono1(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Teléfono 2</td>
                                    <td>
                                        <input id="telefono2"
                                            className="inputboxclienteDetalle"
                                            type="tel"
                                            placeholder="Telefono 2"
                                            defaultValue={telefono2}
                                            maxLength="10"
                                            onChange={(e) => setTelefono2(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>
                                        <input id="email"
                                            className="inputboxclienteDetalle"
                                            type="email"
                                            placeholder="Email"
                                            defaultValue={email}
                                            maxLength="60"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer className="ModalDetalleClienteBody">
                        <Button type="button" variant="secondary" onClick={props.modalNuevoClienteClose}>
                            Cancelar
                        </Button>
                        <Button className="btn-modal-guardar" type="submit">
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ModalSpinner showModalSpinner={showModalSpinner }/>
        </div>
    );
}
export default ModalNuevoCliente;