import "../../css/login.css";
import fpilogoletras from "../../img/fpi-2-2-18.png";
import apiInstance from '../../services/apiConnection';
import { USER_ENDPOINTS } from "../../services/apiEndPoints"
import Form from 'react-bootstrap/Form';
import ReCAPTCHA from "react-google-recaptcha";
import { AlertDescription } from "../alerts/AlertDescription"
import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalDataContext } from "../context/globalData"
import ModalSpinner from "../alerts/modalSpinner";


function LoginForm(props) {

    const gdc = useContext(GlobalDataContext);
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [remember, setRemember] = useState(true);

    const recaptcha = useRef(null);
    const [humano, setHumano] = useState(false);
    const [showModalSpinner, setShowModalSpinner] = useState(false);

    function permitirSubmit() {
        if (recaptcha) {
            setHumano(true);
        } else {
            setHumano(false);
        }
    }

    const loginRequest = (e) => {
        e.preventDefault();
        if (humano) {
            setShowModalSpinner(true);
            apiInstance.post(USER_ENDPOINTS.LOGIN, {
                email: email,
                password: password
            })
                .then((res) => {
                    let data = {
                        email: res.data.usuario,
                        token: res.data.token,
                        type: res.data.type,
                        expiredAt: gdc.calcularExpiredAt()
                    };
                    (remember === true) ? localStorage.setItem("UserSesion", JSON.stringify(data)) : sessionStorage.setItem("UserSesion", JSON.stringify(data));
                    setShowModalSpinner(false);
                    AlertDescription("Correcto", "Inicio de Sesi&oacuten Exitoso", "success");
                    gdc.setMiLogin(true);
                    setHumano(false);
                    gdc.comprobarSesion();
                    navigate("/");
                })
                .catch((error) => {
                    setShowModalSpinner(false);
                    if (error.request.response === "Demasiadas veces, intente de nuevo más tarde") {
                        AlertDescription("Ups!", "Ya intentó demasiadas veces, intente de nuevo más tarde", "error");
                    } else {
                        AlertDescription("Ups!", "Usuario o contraseña incorrectos", "error");
                    }
                });
        }
    }

    useEffect(() => {
        gdc.setShowMenu(true);
        document.body.style.backgroundColor = '#E5E5E5';
    }, [gdc])

    return (
        <div className="fondoGris">
            <link href="https://code.ionicframework.com/ionicons/1.4.1/css/ionicons.min.css" rel="stylesheet" type="text/css" />
            <div className="signin cf">
                <div className="avatar">
                    <img className="login-fpi-logo-letras" src={fpilogoletras} alt="FPI" />
                </div>
                <Form onSubmit={loginRequest}>
                    <div className="inputrow">
                        <input id="email"
                            type="email"
                            placeholder="Email"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <label className="ion-person" htmlFor="email"></label>
                    </div>
                    <div className="inputrow">
                        <input id="pass" type="password"
                            placeholder="Password"
                            required
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <label className="ion-locked" htmlFor="pass"></label>
                    </div>
                    <div className="recaptcha">
                        <ReCAPTCHA
                            ref={recaptcha}
                            sitekey="6Lcb7DMkAAAAANifl8r7oYHWFRP_JUnCM5CC1ffb"
                            onChange={permitirSubmit}
                        />
                    </div>
                    <input id="remember"
                        type="checkbox"
                        name="remember"
                        defaultChecked
                        onChange={(e) => setRemember(!remember)}
                    />
                    <label className="radio" htmlFor="remember">Recordarme</label>
                    <input type="submit" value="Login" disabled={!humano} />
                </Form>
            </div>
            <ModalSpinner showModalSpinner={showModalSpinner} />
        </div>
    )
}
export default LoginForm