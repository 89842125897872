import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import fpilogo from "../../img/logo-fpi-2-2-18.png";
import "../../css/menu.css";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { GlobalDataContext } from "../context/globalData"
import ModalMiCuenta from '../usuarios/modalMiCuenta';



function Menu(props) {

    const gdc = useContext(GlobalDataContext);
    const navigate = useNavigate();
    const [modalMiCuenta, setModalMiCuenta] = useState(false);
    const modalMiCuentaClose = () => setModalMiCuenta(false);
    const modalMiCuentaShow = () => setModalMiCuenta(true);

    function menuAdmin() {
        if (gdc.loggedUser.type === "admin") {
            return (
                <>
                    <Nav.Link onClick={() => { navigate("/seguimiento") }} className="NavLinkMenu">Seguimiento</Nav.Link>
                    <Nav.Link onClick={() => { navigate("/usuarios") }} className="NavLinkMenu">Usuarios</Nav.Link>
                </>
            )
        }
    }

    return (
        <>
            {gdc.showMenu && (<>
                <Navbar className="NavbarMenu" expand="lg" variant="dark">
                    <Container>
                        <Navbar.Brand href="https://fpi.com.co/">
                            <img className="fpi-logo" src={fpilogo} alt="FPI" />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            {gdc.miLogin ? (
                                <Nav className="me-auto">
                                    <Nav.Link className="NavLinkMenu" onClick={() => { navigate("/lista-clientes") }}>
                                        Clientes
                                    </Nav.Link>
                                    {menuAdmin()}
                                    <NavDropdown title={gdc.loggedUser.email} id="basic-nav-dropdown-menu">
                                        <NavDropdown.Item onClick={modalMiCuentaShow} >Mi cuenta</NavDropdown.Item>
                                        <NavDropdown.Item onClick={gdc.logout}>Salir</NavDropdown.Item>
                                    </NavDropdown>
                                </Nav>
                            ) : (
                                <Nav className="me-auto">
                                    <Nav.Link href="https://fpi.com.co/" className="NavLinkMenu">Regresar</Nav.Link>
                                </Nav>
                            )}
                        </Navbar.Collapse>
                    </Container>
                </Navbar >
            </>)}
            {modalMiCuenta && <ModalMiCuenta
                modalMiCuenta={modalMiCuenta}
                modalMiCuentaClose={modalMiCuentaClose}
            />}
        </>
    )
}
export default Menu;