import React from 'react';
import { useNavigate } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import Table from 'react-bootstrap/Table';
import imgGenerarEstudio from "../../img/generar-reporte-light.png";
import imgEliminar from "../../img/eliminar.png";
import eliminarRegistro from "../alerts/eliminar";
import { GlobalDataContext } from "../context/globalData"
import { useContext } from "react";

function AcordionEstudioCredito(props) {

    const gdc = useContext(GlobalDataContext);
    const navigate = useNavigate();
    function generarInforme(id) {
        navigate("/propuesta/" + id);
    }

    return (
        <div>
            <Accordion defaultActiveKey={[0]} alwaysOpen>
                {props.estudio && props.estudio.map((data, id) => {
                    return (
                        <Accordion.Item eventKey={id} className="AcordionEstudioCreditoFondo" key={"Acr" + id}>
                            <Accordion.Header>
                                <div className="btn-outline-fpi-generar-informe" onClick={() => { generarInforme(id) }}>
                                    <img className="nuevoEstudioButtonIco" src={imgGenerarEstudio} alt="FPI" />
                                    Generar informe
                                </div>
                                <div className="AcordionEstudioCreditoHeader">Estudio #{id + 1}</div>
                                {gdc.loggedUser.type === "admin" && <div className="btn-outline-fpi-eliminar-informe" onClick={(e) => { eliminarRegistro(e, gdc.loggedUser, "credito", { "idcredito": data.idcredito }, gdc.actualizar) }}>
                                    <img className="eliminarEstudioButtonIco" src={imgEliminar} alt="FPI" />
                                    Eliminar informe
                                </div>}
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="AcordionEstudioCreditoTablaCredito" >
                                    <Table className="table table-bordered border-dark"
                                        size="sm"
                                        responsive="sm"
                                    >
                                        <thead className="clienteDetalleFondoTitulo" onClick={() => { props.modalDetalleCreditoShow(id) }}>
                                            <tr className="text-center">
                                                <th colSpan={2}>
                                                    DATOS DEL CRÉDITO
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td width="50%">Tipo crédito</td>
                                                <td width="50%">{data.tipo}</td>
                                            </tr>
                                            <tr>
                                                <td>Banco</td>
                                                <td>{data.banco}</td>
                                            </tr>
                                            <tr>
                                                <td>No. Crédito</td>
                                                <td>{data.noCredito}</td>
                                            </tr>
                                            <tr>
                                                <td>Valor Comercial Inmueble</td>
                                                <td>${data.valorComercial.toLocaleString()}</td>
                                            </tr>
                                            <tr>
                                                <td>Valor del Crédito</td>
                                                <td>${data.valorCredito.toLocaleString()}</td>
                                            </tr>
                                            <tr>
                                                <td>Plazo</td>
                                                <td>{data.plazo}</td>
                                            </tr>
                                            <tr>
                                                <td>Tasa de interés</td>
                                                <td>{data.interes}%</td>
                                            </tr>
                                            {(data.tipo === "Hipotecario UVR Baja" || data.tipo === "Hipotecario UVR Media") && (
                                                <>
                                                    <tr>
                                                        <td>Cotiz.inicial $/UVR</td>
                                                        <td>{data.uvr}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Variación anual UVR</td>
                                                        <td>{data.variacionUvr}%</td>
                                                    </tr>
                                                </>
                                            )}
                                            <tr>
                                                <td>Ingresos</td>
                                                <td>${Math.round(data.ingresos).toLocaleString()}</td>
                                            </tr>
                                            <tr>
                                                <td>Cuota máxima</td>
                                                <td>${Math.round(data.ingresos * 0.3).toLocaleString()}</td>
                                            </tr>
                                            <tr>
                                                <td>Cuota actual</td>
                                                <td>${Math.round(data.cuotaMensual).toLocaleString()}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="AcordionEstudioCreditoTablaOpcionesNegociacion" >
                                    <Table className="table table-bordered border-dark"
                                        size="sm"
                                        responsive="sm"
                                    >
                                        <thead className="clienteDetalleFondoTitulo" onClick={() => { props.modalDetallePropuestasShow(id) }}>
                                            <tr className="text-center">
                                                <th colSpan={5}>
                                                    OPCIONES DE NEGOCIACIÓN
                                                </th>
                                            </tr>
                                            <tr className="text-center">
                                                <th>
                                                    Condiciones
                                                </th>
                                                <th>
                                                    Banco actual
                                                </th>
                                                <th>
                                                    Propuesta 1
                                                </th>
                                                <th>
                                                    Propuesta 2
                                                </th>
                                                <th>
                                                    Propuesta 3
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Plazo</td>
                                                <td align="center">{data.plazo}</td>
                                                <td align="center">{data.plazo1}</td>
                                                <td align="center">{data.plazo2}</td>
                                                <td align="center">{data.plazo3}</td>
                                            </tr>
                                            {gdc.loggedUser.type === "admin" && ( < tr >
                                                <td>Tasa de interés</td>
                                                <td align="center">{data.interes}%</td>
                                                <td align="center">{data.tasa1}%</td>
                                                <td align="center">{data.tasa2}%</td>
                                                <td align="center">{data.tasa3}%</td>
                                            </tr>) }
                                            <tr>
                                                <td>Valor cuota</td>
                                                <td align="right">${Math.round(data.tabla0.CuotaTotalPesos[0]).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.tabla1.CuotaTotalPesos[0]).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.tabla2.CuotaTotalPesos[0]).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.tabla3.CuotaTotalPesos[0]).toLocaleString()}</td>
                                            </tr>
                                            <tr>
                                                <td>Interés</td>
                                                <td align="right">${Math.round(data.tabla0.CuotaInteresPesos[0]).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.tabla1.CuotaInteresPesos[0]).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.tabla2.CuotaInteresPesos[0]).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.tabla3.CuotaInteresPesos[0]).toLocaleString()}</td>
                                            </tr>
                                            <tr>
                                                <td>Capital</td>
                                                <td align="right">${Math.round(data.tabla0.CuotaCapitalPesos[0]).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.tabla1.CuotaCapitalPesos[0]).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.tabla2.CuotaCapitalPesos[0]).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.tabla3.CuotaCapitalPesos[0]).toLocaleString()}</td>
                                            </tr>
                                            <tr>
                                                <td>Frech</td>
                                                <td align="right">${Math.round(data.tabla0.CuotaInteresPesosCobertura[0]).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.tabla1.CuotaInteresPesosCobertura[0]).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.tabla2.CuotaInteresPesosCobertura[0]).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.tabla3.CuotaInteresPesosCobertura[0]).toLocaleString()}</td>
                                            </tr>
                                            <tr>
                                                <td>Seguro</td>
                                                <td align="right">${Math.round(data.seguro).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.seguro1).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.seguro2).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.seguro3).toLocaleString()}</td>
                                            </tr>
                                            <tr>
                                                <td>Total pagado</td>
                                                <td align="right">${Math.round(data.tabla0.PagoTotal).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.tabla1.PagoTotal).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.tabla2.PagoTotal).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.tabla3.PagoTotal).toLocaleString()}</td>
                                            </tr>
                                            <tr>
                                                <td><b>AHORRO</b></td>
                                                <td align="right"><b>0</b></td>
                                                <td align="right"><b>${Math.round(data.tabla0.PagoTotal - data.tabla1.PagoTotal).toLocaleString()}</b></td>
                                                <td align="right"><b>${Math.round(data.tabla0.PagoTotal - data.tabla2.PagoTotal).toLocaleString()}</b></td>
                                                <td align="right"><b>${Math.round(data.tabla0.PagoTotal - data.tabla3.PagoTotal).toLocaleString()}</b></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="AcordionEstudioCreditoTablaSeguros" >
                                    <Table className="table table-bordered border-dark" size="sm" responsive="sm">
                                        <thead className="">
                                            <tr className="text-center">
                                                <th colSpan={5}>
                                                    SEGURO
                                                </th>
                                            </tr>
                                            <tr className="text-center">
                                                <th width="20%">
                                                    Condiciones
                                                </th>
                                                <th width="20%">
                                                    Banco actual
                                                </th>
                                                <th width="20%">
                                                    FPI 1
                                                </th>
                                                <th width="20%">
                                                    FPI 2
                                                </th>
                                                <th width="20%">
                                                    FPI 3
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Pago mensual</td>
                                                <td align="right">${Math.round(data.seguro).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.seguro1).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.seguro2).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.seguro3).toLocaleString()}</td>
                                            </tr>
                                            <tr>
                                                <td>Pago anual</td>
                                                <td align="right">${Math.round(data.seguro * 12).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.seguro1 * 12).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.seguro2 * 12).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.seguro3 * 12).toLocaleString()}</td>
                                            </tr>
                                            <tr>
                                                <td>Pago total</td>
                                                <td align="right">${Math.round(data.seguro * data.plazo).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.seguro * data.plazo1).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.seguro * data.plazo2).toLocaleString()}</td>
                                                <td align="right">${Math.round(data.seguro * data.plazo3).toLocaleString()}</td>
                                            </tr>
                                            <tr>
                                                <td><b>Ahorro</b></td>
                                                <td align="right"><b>${Math.round(data.seguro * data.plazo - data.seguro * data.plazo).toLocaleString()}</b></td>
                                                <td align="right"><b>${Math.round(data.seguro * data.plazo - data.seguro * data.plazo1).toLocaleString()}</b></td>
                                                <td align="right"><b>${Math.round(data.seguro * data.plazo - data.seguro * data.plazo2).toLocaleString()}</b></td>
                                                <td align="right"><b>${Math.round(data.seguro * data.plazo - data.seguro * data.plazo3).toLocaleString()}</b></td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    )
                })}
            </Accordion>
        </div>
    )
}
export default AcordionEstudioCredito;