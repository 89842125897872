import { createContext } from 'react';
import { useState, useEffect } from "react";
import { AlertDescription } from "../alerts/AlertDescription"
import { useNavigate, } from "react-router-dom";
import React from 'react';

export const GlobalDataContext = createContext({});

export default function GlobalDataContextProvider({ children }) {

    const [loggedUser, setLoggedUser] = useState("");
    const navigate = useNavigate();
    const logout = () => {
        setMiLogin(false);
        window.localStorage.removeItem("UserSesion");
        window.sessionStorage.removeItem("UserSesion");
        setLoggedUser("");
        navigate("/")
    }
    const comprobarSesion = () => {
        var localSesion = JSON.parse(localStorage.getItem("UserSesion"));
        var sesion = JSON.parse(sessionStorage.getItem("UserSesion"));
        var currenDate = new Date();
        var time = currenDate.getTime();
        var expiraEn;
        if (localSesion) {
            expiraEn = (new Date(localSesion.expiredAt) - time)/1000/60;
            if (expiraEn > 0) {
                if (loggedUser === "") {
                    localSesion.type = localSesion.type === 1413914 ? "admin" : localSesion.type === 1205201619 ? "asesor" : "direct"
                    setLoggedUser(localSesion);
                }
                if (expiraEn <= 5) AlertDescription("Pilas!", "La sesión está a punto de expirar, le quedan menos de " + Math.round(expiraEn) + " minutos", "warning");
                return true;
            } else {
                AlertDescription("Upps!", "La sesión ha expirado, ingrese de nuevo", "error").then(logout());
                return false;
            }
        } else if (sesion) {
            expiraEn = (new Date(sesion.expiredAt) - time) / 1000 / 60;
            if (expiraEn > 0) {
                if (loggedUser === "") {
                    sesion.type = sesion.type === 1413914 ? "admin" : sesion.type === 1205201619 ? "asesor" : "direct"
                    setLoggedUser(sesion);
                }
                if (expiraEn <= 5) AlertDescription("!Pilas!", "La sesión está a punto de expirar, le quedan menos de " + Math.round(expiraEn) + " minutos. Deberá iniciar sesión nuevamente", "warning");
                return true;
            } else {
                AlertDescription("Upps!", "La sesión ha expirado, ingrese de nuevo", "error").then(logout());
                return false;
            }
        }
        else {
            return false;
        }
    }

    const [miLogin, setMiLogin] = useState(false);
    const [cliente, setCliente] = useState();
    const [actualiza, setActualiza] = useState();
    const actualizar = () => {
        setActualiza(!actualiza);
    }
    const [rows, setRows] = useState();
    const [showMenu, setShowMenu] = useState();

    function calcularExpiredAt() {
        var currenDate = new Date();
        var time = currenDate.getTime();
        var addMlSeconds = 1000 * 60 * 118;
        var newDate = new Date(time + addMlSeconds);
        return newDate;
    }
     
    useEffect(() => {
        setRows(5);
        setActualiza(false);
        setCliente({});
        setMiLogin(comprobarSesion);
    }, [])

    return (
        <GlobalDataContext.Provider value={{ showMenu, setShowMenu, loggedUser, setLoggedUser, miLogin, setMiLogin, comprobarSesion, calcularExpiredAt, logout, cliente, setCliente, actualizar, rows, setRows }}>
            {children }
        </GlobalDataContext.Provider>
        )
}