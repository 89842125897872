import LoginForm from "../components/login/loginForm";
import PropuestaDetalle from "../components/report/propuestaDetalle"
import React from 'react';
import { useContext } from "react";
import { GlobalDataContext } from "../components/context/globalData"

function Propuesta(props) {

    const gdc = useContext(GlobalDataContext);

    return (
        <div >
            {gdc.miLogin === false && <LoginForm />}
            {gdc.miLogin && <PropuestaDetalle />}
        </div>
    );
}

export default Propuesta