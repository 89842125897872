import React from 'react';
import Table from 'react-bootstrap/Table';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,  ResponsiveContainer } from 'recharts';

function GraficoComportamientoCredito(props) {

    const series = [
        {
            name: 'Situación Actual',
            data: generarSerie(0),
            color: "#880015"
        },
        {
            name: 'Propuesta 1',
            data: generarSerie(1),
            color: "#F5811E"
        },
        {
            name: 'Propuesta 2',
            data: generarSerie(2),
            color: "#0C6F8F"
        },
        {
            name: 'Propuesta 3',
            data: generarSerie(3),
            color: "#92D050"
        },
    ];

    function generarSerie(id) {

        if (props.estudio) {
            let dataSeries = [];
            let tabla;
            let tiempo = 20;
            switch (id) {
                case 0:
                    tabla = props.estudio.tabla0.SaldoFinalPesos;
                    tiempo = Math.round(props.estudio.tabla0.SaldoFinalPesos.length / 12);
                    break;
                case 1:
                    tabla = props.estudio.tabla1.SaldoFinalPesos;
                    tiempo = Math.round(props.estudio.tabla1.SaldoFinalPesos.length / 12);
                    break;
                case 2:
                    tabla = props.estudio.tabla2.SaldoFinalPesos;
                    tiempo = Math.round(props.estudio.tabla2.SaldoFinalPesos.length / 12);
                    break;
                case 3:
                    tabla = props.estudio.tabla3.SaldoFinalPesos;
                    tiempo = Math.round(props.estudio.tabla3.SaldoFinalPesos.length / 12);
                    break;
                default:
                    tabla = [];
                    tiempo = 0;
            }
            for (let anio = 0; anio <= tiempo; anio++) {
                dataSeries.push({
                    category: anio,
                    value: tabla[anio * 12] ? tabla[anio * 12]/1000000 : 0
                })
            }
            return dataSeries;
        } else { return [] }
    }

    return (<>
        <div>
            <Table className="table table-bordered border-dark"
                size="sm"
                responsive="sm"
            >
                <thead >
                    <tr className="text-center">
                        <th colSpan={9}>
                            COMPORTAMIENTO CRÉDITO
                        </th>
                    </tr>
                </ thead >
            </ Table >
        </div>
        <ResponsiveContainer width="100%" height="80%" >
            <LineChart width={500} height={400}>
                <CartesianGrid vertical={false} />
                <XAxis dataKey="category" type="category" allowDuplicatedCategory={false} /> 
                <YAxis dataKey="value" tickSize="0" label={{ value: 'Millones', angle: -90, position: 'insideLeft' }} />
                <Tooltip />
                <Legend height ="50" />
                {series.map((s) => (
                    <Line type="monotone" dataKey="value" data={s.data} name={s.name} key={s.name} stroke={s.color} dot={false} strokeWidth={3} />
                ))}
            </LineChart>
        </ResponsiveContainer>
    </>
    )
}
export default GraficoComportamientoCredito;