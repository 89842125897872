import React from "react";
import Modal from 'react-bootstrap/Modal';
import fpispiner from "../../img/spiner-ico.png";

function ModalSpinner(props) {

    return (
        <Modal
            show={props.showModalSpinner}
            backdrop="static"
            keyboard={false}
            centered
            contentClassName="modalFpiSpinner"
        >
            <img className="fpispinner" src={fpispiner}
                alt="fpi-spiner"
            />
        </Modal>
        )
}

export default ModalSpinner