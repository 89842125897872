import React from 'react';
import { useState, useContext } from "react";
import { GlobalDataContext } from "../context/globalData"
import apiInstance from '../../services/apiConnection';
import { CREDITOS_ENDPOINTS } from "../../services/apiEndPoints"
import { AlertDescription } from "../alerts/AlertDescription"
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import fpilogoletras from "../../img/fpi-2-2-18-Dark.png";
import ModalSpinner from '../alerts/modalSpinner';

function ModalActualizaSeguimiento(props) {

    const gdc = useContext(GlobalDataContext);
    const [honorarios, setHonorarios] = useState(props.data.valorContrato);
    const [estado, setEstado] = useState(props.data.estado);
    const [fechaEstado, setFechaEstado] = useState(new Date(props.data.fechaEstado).toISOString().split('T')[0]);
    const [observaciones, setObservaciones] = useState(props.data.observaciones);
    const [showModalSpinner, setShowModalSpinner] = useState(false);

    function actualizarSeguimmiento(e) {
        e.preventDefault();
        setShowModalSpinner(true);
        const headers = {
            "Authorization": "Bearer " + gdc.loggedUser.token,
        };

        const body = {
            idcredito: props.data.idcredito,
            cedula: props.data.cedula,
            tipo: props.data.tipo,
            banco: props.data.banco,
            noCredito: props.data.noCredito,
            valorComercial: props.data.valorComercial ? props.data.valorComercial : 0,
            valorCredito: props.data.valorCredito,
            ingresos: props.data.ingresos ? props.data.ingresos : 0,
            interes: props.data.interes,
            plazo: props.data.plazo,
            uvr: (props.data.tipo === "Hipotecario UVR Baja" || props.data.tipo === "Hipotecario UVR Media") ? props.data.uvr : 0,
            variacionUvr: (props.data.tipo === "Hipotecario UVR Baja" || props.data.tipo === "Hipotecario UVR Media") ? props.data.variacionUvr : 0,
            seguro: props.data.seguro ? props.data.seguro : 0,
            cuotaMensual: props.data.cuotaMensual ? props.data.cuotaMensual : 0,
            puntosCobertura: props.data.puntosCobertura ? props.data.puntosCobertura : 0,
            estado: estado,
            fechaEstado: fechaEstado,
            valorContrato: honorarios ? honorarios : 0,
            observaciones: observaciones ? observaciones : ""
        }
        apiInstance.patch(CREDITOS_ENDPOINTS.EDITAR, body, { headers: headers })
            .then((res) => {
                setShowModalSpinner(false);
                AlertDescription("Correcto", "Datos guardados corréctamente", "success");
                props.listarClientes(gdc.loggedUser);
                props.modalActualizaSeguimientoClose();
            })
            .catch((error) => {
                setShowModalSpinner(false);
                AlertDescription("Ups!", "No se pudo crear el estudio de crédito. Contacte al adminisrador.", "error");
            });
    }

    return (
        <div>
            <Modal
                show={props.modalActualizaSeguimiento}
                onHide={props.modalActualizaSeguimientoClose}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header closeButton className="ModalDetalleClienteHeader">
                    <Modal.Title >
                        <span> | </span>
                        <img className="login-fpi-logo-letras-ModalDetalleClienteHeader" src={fpilogoletras} alt="FPI" />
                        <span> | </span>
                        Seguimiento Clientes
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={actualizarSeguimmiento}>
                    <Modal.Body className="ModalDetalleClienteBody">
                        <Table className="table table-bordered border-dark" size="sm">
                            <thead className="clienteDetalleFondoTitulo">
                                <tr className="text-center">
                                    <th colSpan={2}>
                                        ESTADO DEL ESTUDIO
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td width="50%">Honorarios Pactados</td>
                                    <td width="50%">
                                        <input id="honorarios"
                                            className="inputboxclienteDetalle"
                                            type="number"
                                            placeholder="Honorarios Pactados"
                                            defaultValue={honorarios}
                                            maxLength="15"
                                            required
                                            onChange={(e) => {setHonorarios(e.target.value ? parseFloat(e.target.value) : e.target.value)}}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Estado</td>
                                    <td>
                                        <Form.Select
                                            aria-label="Estado"
                                            defaultValue={estado}
                                            required
                                            onChange={(e) => setEstado(e.target.value)}
                                        >
                                            <option value="01 Estudio">01 Estudio</option>
                                            <option value="02 Cita de cliente pendiente">02 Cita de cliente pendiente</option>
                                            <option value="03 Cliente visitado">03 Cliente visitado</option>
                                            <option value="04 Contrato firmado">04 Contrato firmado</option>
                                            <option value="05 Documentos radicados en Banco">05 Documentos radicados en Banco</option>
                                            <option value="06 Certificación entregada a FPI">06 Certificación entregada a FPI</option>
                                            <option value="07 Resultados y Factura entregada a cliente">07 Resultados y Factura entregada a cliente</option>
                                            <option value="08 Honorarios pagados">08 Honorarios pagados</option>
                                            <option value="09 No viable">09 No viable</option>
                                        </Form.Select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Fecha Estado</td>
                                    <td>
                                        <input id="fechaEstado"
                                            className="inputboxclienteDetalle"
                                            type="date"
                                            placeholder="Fecha Estado"
                                            required
                                            value={fechaEstado}
                                            onChange={(e) => setFechaEstado(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Observaciones</td>
                                    <td>
                                        <input id="observaciones"
                                            className="inputboxclienteDetalle"
                                            type="text"
                                            placeholder="Observaciones"
                                            defaultValue={observaciones}
                                            maxLength="255"
                                            onChange={(e) => setObservaciones(e.target.value)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer className="ModalDetalleClienteBody">
                        <Button type="button" variant="secondary" onClick={props.modalActualizaSeguimientoClose}>
                            Cancelar
                        </Button>
                        <Button className="btn-modal-guardar" type="submit">
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ModalSpinner showModalSpinner={showModalSpinner }/>
        </div>
    );
}
export default ModalActualizaSeguimiento;