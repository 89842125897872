import React from 'react';
import Table from 'react-bootstrap/Table';

function TablaSeguro(props) {

    function MostrarTabla() {
        if (props.estudio) {
            return (
                <Table className="table table-bordered border-dark"
                    size="sm"
                    responsive="sm"
                >
                    <thead >
                        <tr className="text-center">
                            <th colSpan={4}>
                                SEGUROS
                            </th>
                        </tr>
                        <tr className="text-center">
                            <th> </th>
                            <th >
                                MENSUAL
                            </th>
                            <th >
                                ANUAL
                            </th>
                            <th >
                                VIGENCIA C/TO
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td width="25%">Seguros Banco</td>
                            <td width="25%" align="right">
                                ${Math.round(props.estudio.seguro).toLocaleString()}
                            </td>
                            <td width="25%" align="right">
                                ${Math.round(props.estudio.seguro * 12).toLocaleString()}
                            </td>
                            <td width="25%" align="right">
                                ${Math.round(props.estudio.seguro * props.estudio.plazo).toLocaleString()}
                            </td>
                        </tr>
                        <tr>
                            <td width="25%">Seguros FPI</td>
                            <td width="25%" align="right">
                                ${Math.round(props.estudio.seguro1).toLocaleString()}
                            </td>
                            <td width="25%" align="right">
                                ${Math.round(props.estudio.seguro1 * 12).toLocaleString()}
                            </td>
                            <td width="25%" align="right">
                                ${Math.round(props.estudio.seguro1 * props.estudio.plazo1).toLocaleString()}
                            </td>
                        </tr>
                        <tr>
                            <td width="25%"><div className="propuestaDetalleNegrita" >AHORRO</div></td>
                            <td width="25%" align="right"> <div className="propuestaDetalleNegrita" >
                                ${Math.round((props.estudio.seguro - props.estudio.seguro1)).toLocaleString()}
                            </div>
                            </td>
                            <td width="25%" align="right"> <div className="propuestaDetalleNegrita" >
                                ${Math.round((props.estudio.seguro - props.estudio.seguro1) * 12).toLocaleString()}
                            </div>
                            </td>
                            <td width="25%" align="right"> <div className="propuestaDetalleNegrita" >
                                ${Math.round((props.estudio.seguro * props.estudio.plazo) - (props.estudio.seguro1 * props.estudio.plazo1)).toLocaleString()}
                            </div>
                            </td>
                        </tr>
                    </tbody>
                </Table >
            )
        }
    }

    return (
        <div>
            <MostrarTabla />
        </div>
    );
}
export default TablaSeguro;