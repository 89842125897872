import LoginForm from "../components/login/loginForm";
import UsuariosDetalle from "../components/usuarios/usuariosDetalle";
import Error404 from "./error404";
import React from 'react';
import { useContext } from "react";
import { GlobalDataContext } from "../components/context/globalData"


function Usuarios(props) {

    const gdc = useContext(GlobalDataContext);

    return (
        <div >
            {gdc.miLogin === false && <LoginForm />}
            {gdc.miLogin && gdc.loggedUser.type === "admin" && <UsuariosDetalle />}
            {gdc.miLogin && gdc.loggedUser.type !== "admin" && <Error404 />}
        </div>
    );
}

export default Usuarios;