import "../../css/clientes.css";
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { AlertDescription } from "../alerts/AlertDescription"
import PaginationControl from "../menu/paginationControl"
import ModalNuevoCliente from "./modalNuevoCliente"
import apiInstance from '../../services/apiConnection';
import { CLIENTES_ENDPOINTS } from "../../services/apiEndPoints"
import { useNavigate } from "react-router-dom";
import React from 'react';
import { useEffect, useState, useContext } from "react";
import { GlobalDataContext } from "../context/globalData"
import imgNuevocliente from "../../img/agregar-cliente.png";
import imgEliminar from "../../img/eliminar.png";
import eliminarRegistro from "../alerts/eliminar";

function Clientes(props) {

    const ImgDes = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" className="bi bi-arrow-up-circle" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8zm15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-7.5 3.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V11.5z" />
            </svg>
        );
    }
    const navigate = useNavigate();
    const gdc = useContext(GlobalDataContext);
    const [listaClientes, setListaClientes] = useState();
    const [modalNuevoCliente, setModalNuevoCliente] = useState(false);
    const modalNuevoClienteClose = () => setModalNuevoCliente(false);
    const modalNuevoClienteShow = () => setModalNuevoCliente(true);
    const [page, setPage] = useState(1);
    const listarClientes = (loggedUser) => {
        const headers = {
            "Authorization": "Bearer " + loggedUser.token
        };
        apiInstance.get(CLIENTES_ENDPOINTS.LISTAR, { headers: headers })
            .then((res) => {
                let data = res.data;
                setListaClientes(data);
            })
            .catch((error) => {
                AlertDescription("Ups!", "No se pudo cargar la lista de clientes", "error");
            });

    }
    const navegarCliente = (data) => {
        gdc.setCliente(data);
        navigate("/cliente");
    }

    useEffect(() => {
        gdc.comprobarSesion();
        listarClientes(gdc.loggedUser)
        gdc.setShowMenu(true);
        document.body.style.backgroundColor = '#E5E5E5';
    }, [gdc])

    return (
        <div className="fondoGris">
            <div className="tituloClientes">
                Clientes
            </div>
            <div className="tituloClientesBoton">
                <Button className="btn-outline-fpi" onClick={modalNuevoClienteShow}>
                    <img className="nuevoClienteButtonIco" src={imgNuevocliente} alt="FPI" />
                    Nuevo cliente
                </Button>
            </div>
            <hr />
            <div className="contenedorTablaClientes">
                <Table striped bordered hover
                    size="sm"
                    responsive="sm"
                >
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>Fecha <ImgDes /></th>
                            <th>Nombre</th>
                            <th>Ciudad</th>
                            <th>Teléfono 1</th>
                            <th>Teléfono 2</th>
                            <th>Email</th>
                            {gdc.loggedUser.type === "admin" && <th className="text-center">Eliminar</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {listaClientes && listaClientes.map((data, index) => {
                            if (index >= (page * gdc.rows - gdc.rows) && index < (page * gdc.rows)) {
                                return (
                                    <tr key={data.cedula + "230112203"}>
                                        <td onClick={() => { navegarCliente(data) }}>{index + 1}</td>
                                        <td onClick={() => { navegarCliente(data) }}>{new Date(data.fechaCreacion).toLocaleDateString()}</td>
                                        <td onClick={() => { navegarCliente(data) }}>{data.nombre} {data.apellido}</td>
                                        <td onClick={() => { navegarCliente(data) }}>{data.ciudad}</td>
                                        <td onClick={() => { navegarCliente(data) }}>{data.telefono1}</td>
                                        <td onClick={() => { navegarCliente(data) }}>{data.telefono2}</td>
                                        <td onClick={() => { navegarCliente(data) }}>{data.email}</td>

                                        {gdc.loggedUser.type === "admin" && (
                                            <td align="center">
                                                <Button className="btn-outline-fpi-eliminar" onClick={(e) => { eliminarRegistro(e, gdc.loggedUser, "cliente", { cedula: data.cedula }, listarClientes) }}>
                                                    <img className="eliminarButtonIco" src={imgEliminar} alt="FPI" />
                                                </Button>
                                            </td>)
                                        }
                                    </tr>
                                )
                            } else { return null }
                        })}
                    </tbody>
                </Table>
            </div>
            <hr />
            <div className="listaClientesPaginationControl">
                {listaClientes && <PaginationControl rows={gdc.rows} page={page} setPage={setPage} setRows={gdc.setRows} maxPage={Math.ceil(listaClientes.length / gdc.rows)} />}
            </div>
            {modalNuevoCliente && <ModalNuevoCliente
                modalNuevoCliente={modalNuevoCliente}
                modalNuevoClienteClose={modalNuevoClienteClose}
                modalNuevoClienteShow={modalNuevoClienteShow}
                listarClientes={listarClientes}
            />}
        </div>
    )
}
export default Clientes;