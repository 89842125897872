import LoginForm from "../components/login/loginForm";
import Clientes from "../components/clientes/clientes";
import React from 'react';
import { useContext } from "react";
import { GlobalDataContext } from "../components/context/globalData"

function Dashboard(props) {

    const gdc = useContext(GlobalDataContext);

    return (
        <div >
            {gdc.miLogin === false && <LoginForm />}
            {gdc.miLogin && <Clientes  />}
        </div>
    );
}

export default Dashboard;