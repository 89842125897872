
export const USER_ENDPOINTS = {
    LOGIN: process.env.REACT_APP_LOGIN,
    REFRESH: "/refresh",
    VERIFICAR: "/verify",
};

export const CLIENTES_ENDPOINTS = {
    LISTAR: process.env.REACT_APP_CLIENTESLISTAR,
    CREAR: process.env.REACT_APP_CLIENTESCREAR,
    EDITAR: process.env.REACT_APP_CLIENTESEDITAR,
    ELIMINAR: process.env.REACT_APP_CLIENTESELIMINAR
}

export const CREDITOS_ENDPOINTS = {
    LISTARCLIENTE: process.env.REACT_APP_CREDITOSLISTARCLIENTE,
    LISTARIDCREDITO: process.env.REACT_APP_CREDITOSLISTARIDCREDITO,
    CREAR: process.env.REACT_APP_CREDITOSCREAR,
    EDITAR: process.env.REACT_APP_CREDITOSEDITAR,
    ELIMINAR: process.env.REACT_APP_CREDITOSELIMINAR
}

export const PROPUESTAS_ENDPOINTS = {
    LISTARCLIENTE: process.env.REACT_APP_PROPUESTASLISTARCLIENTE,
    LISTARIDCREDITO: process.env.REACT_APP_PROPUESTASLISTARIDCREDITO,
    CREAR: process.env.REACT_APP_PROPUESTASCREAR,
    EDITAR: process.env.REACT_APP_PROPUESTASEDITAR,
    ELIMINAR: process.env.REACT_APP_PROPUESTASELIMINAR,
    SEGUIMIENTOCLEINTE: process.env.REACT_APP_PROPUESTASSEGUIMIENTOCLEINTE
}

export const USUARIOS_ENDPOINTS = {
    MIUSUARIO: process.env.REACT_APP_USUARIOSMIUSUARIO,
    LISTARUSUARIOS: process.env.REACT_APP_USUARIOSLISTARUSUARIOS,
    CREAR: process.env.REACT_APP_USUARIOSCREAR,
    EDITAR: process.env.REACT_APP_USUARIOSEDITAR,
    ELIMINAR: process.env.REACT_APP_USUARIOSELIMINAR
}