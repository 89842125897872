import React from 'react';
import { useState, useContext } from "react";
import { GlobalDataContext } from "../context/globalData"
import Table from 'react-bootstrap/Table';
import ModalDetalleCliente from "./modalDetalleCliente"

function TablaDetalleCliente(props) {

    const gdc = useContext(GlobalDataContext);
    const [modalDetalleCliente, setModalDetalleCliente] = useState(false);
    const modalDetalleClienteClose = () => setModalDetalleCliente(false);
    const modalDetalleClienteShow = () => setModalDetalleCliente(true);

    return (
        <div>
            <Table className="table table-bordered border-dark" size="sm" >
                <thead className="clienteDetalleFondoTitulo" onClick={modalDetalleClienteShow}>
                    <tr className="text-center">
                        <th colSpan={2}>
                            DATOS DEL CLIENTE
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td width="50%">Cédula</td>
                        <td width="50%">{gdc.cliente.cedula}</td>
                    </tr>
                    <tr>
                        <td>Nombre</td>
                        <td>
                            {gdc.cliente.nombre}
                        </td>
                    </tr>
                    <tr>
                        <td>Apellido</td>
                        <td>
                            {gdc.cliente.apellido}
                        </td>
                    </tr>
                    <tr>
                        <td>Fecha Nacimiento</td>
                        <td>
                            {gdc.cliente.fechanac ? new Date(gdc.cliente.fechanac).toLocaleDateString() : ""}
                        </td>
                    </tr>
                    <tr>
                        <td>Ciudad</td>
                        <td>
                            {gdc.cliente.ciudad}
                        </td>
                    </tr>
                    <tr>
                        <td>Teléfono 1</td>
                        <td>
                            {gdc.cliente.telefono1}
                        </td>
                    </tr>
                    <tr>
                        <td>Teléfono 2</td>
                        <td>
                            {gdc.cliente.telefono2}
                        </td>
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td>
                            {gdc.cliente.email}
                        </td>
                    </tr>
                    <tr>
                        <td>Asesor</td>
                        <td>{gdc.cliente.asesor}</td>
                    </tr>
                </tbody>
            </Table>
            {modalDetalleCliente && <ModalDetalleCliente
                modalDetalleCliente={modalDetalleCliente}
                modalDetalleClienteClose={modalDetalleClienteClose}
                modalDetalleClienteShow={modalDetalleClienteShow}
            />}
        </div>
    );
}
export default TablaDetalleCliente;