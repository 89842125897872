import React from 'react';
import { useState, useContext } from "react";
import { GlobalDataContext } from "../context/globalData"
import apiInstance from '../../services/apiConnection';
import { CREDITOS_ENDPOINTS, PROPUESTAS_ENDPOINTS } from "../../services/apiEndPoints"
import { AlertDescription } from "../alerts/AlertDescription"
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import fpilogoletras from "../../img/fpi-2-2-18-Dark.png";
import ModalSpinner from '../alerts/modalSpinner';

function ModalNuevoEstudio(props) {

    const gdc = useContext(GlobalDataContext);

    const [tipo, setTipo] = useState("Leasing");
    const [banco, setBanco] = useState("");
    const [noCredito, setNoCredito] = useState("");
    const [valorComercial, setValorComercial] = useState(0);
    const [valorCredito, setValorCredito] = useState(0);
    const [plazo, setPlazo] = useState(0);
    const [interes, setInteres] = useState(0);
    const [uvr, setUvr] = useState(0);
    const [variacionUvr, setVariacionUvr] = useState(0);
    const [ingresos, setIngresos] = useState(0);
    const [cuotaMensual, setCuotaMensual] = useState(0);
    const [seguro, setSeguro] = useState(0);
    const [puntosCobertura, setPuntosCobertura] = useState(0);
    const [showModalSpinner, setShowModalSpinner] = useState(false);
    
    function crearEstudio(e) {
        e.preventDefault();
        setShowModalSpinner(true);
        const headers = {
            "Authorization": "Bearer " + gdc.loggedUser.token,
        };
        const body = {
            cedula: gdc.cliente.cedula, 
            tipo: tipo,
            banco: banco,
            noCredito: noCredito,
            valorComercial: valorComercial ? valorComercial:0,
            valorCredito: valorCredito,
            ingresos: ingresos ? ingresos:0,
            interes: interes,
            plazo: plazo,
            uvr: (tipo === "Hipotecario UVR Baja" || tipo === "Hipotecario UVR Media") ? uvr:0,
            variacionUvr: (tipo === "Hipotecario UVR Baja" || tipo === "Hipotecario UVR Media") ? variacionUvr : 0,
            seguro: seguro ? seguro:0,
            cuotaMensual: cuotaMensual ? cuotaMensual:0,
            puntosCobertura: puntosCobertura ? puntosCobertura:0
        }
        apiInstance.post(CREDITOS_ENDPOINTS.CREAR, body, { headers: headers })
            .then((res) => {
                apiInstance.post(PROPUESTAS_ENDPOINTS.CREAR, { "idcredito": res.data.insertId } , { headers: headers })
                    .then((res1) => {
                        setShowModalSpinner(false);
                        AlertDescription("Correcto", "Datos guardados corréctamente", "success");
                    })
                    .catch((error) => {
                        setShowModalSpinner(false);
                        AlertDescription("Ups!", "Se guardaron los datos del crédito, pero no se generaron las propuestas, contacte al Administrador", "error");
                    });
                gdc.actualizar();
                props.modalNuevoEstudioClose();
            })
            .catch((error) => {
                setShowModalSpinner(false);
                AlertDescription("Ups!", "No se pudo crear el estudio de crédito. Contacte al adminisrador.", "error");
            });
    }

    return (
        <div>
            <Modal
                show={props.modalNuevoEstudio}
                onHide={props.modalNuevoEstudioClose}
                backdrop="static"
                keyboard={true}
            >
                <Modal.Header closeButton className="ModalDetalleClienteHeader">
                    <Modal.Title >
                        <span> | </span>
                        <img className="login-fpi-logo-letras-ModalDetalleClienteHeader" src={fpilogoletras} alt="FPI" />
                        <span> | </span>
                        Nuevo Estudio de Crédito
                    </Modal.Title>
                </Modal.Header>
                <Form onSubmit={crearEstudio}>
                    <Modal.Body className="ModalDetalleClienteBody">
                        <Table className="table table-bordered border-dark" size="sm">
                            <thead className="clienteDetalleFondoTitulo">
                                <tr className="text-center">
                                    <th colSpan={2}>
                                        DATOS DEL CRÉDITO
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Tipo</td>
                                    <td>
                                        <Form.Select
                                            aria-label="Tipo de credito"
                                            required
                                            defaultValue={tipo}
                                            onChange={(e) => setTipo(e.target.value) }
                                        >
                                            <option value="Leasing">Leasing</option>
                                            <option value="Hipotecario Pesos">Hipotecario Pesos</option>
                                            <option value="Hipotecario UVR Baja">Hipotecario UVR Baja</option>
                                            <option value="Hipotecario UVR Media">Hipotecario UVR Media</option>
                                        </Form.Select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Banco</td>
                                    <td>
                                        <input id="banco"
                                            className="inputboxclienteDetalle"
                                            type="text"
                                            placeholder="Banco"
                                            required
                                            defaultValue={banco}
                                            maxLength="45"
                                            onChange={(e) => setBanco((e.target.value).toUpperCase())}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>No. Crédito</td>
                                    <td>
                                        <input id="noCredito"
                                            className="inputboxclienteDetalle"
                                            type="text"
                                            placeholder="No. Crédito"
                                            required
                                            defaultValue={noCredito}
                                            maxLength="25"
                                            onChange={(e) => setNoCredito(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Valor Comercial Inmueble</td>
                                    <td>
                                        <input id="valorComercial"
                                            className="inputboxclienteDetalle"
                                            type="number"
                                            placeholder="Valor Comercial Inmueble"
                                            value={valorComercial}
                                            maxLength="15"
                                            onChange={(e) => setValorComercial(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Valor del Crédito</td>
                                    <td>
                                        <input id="valorCredito"
                                            className="inputboxclienteDetalle"
                                            type="number"
                                            placeholder="Valor del Credito"
                                            required
                                            value={valorCredito}
                                            maxLength="15"
                                            onChange={(e) => setValorCredito(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Plazo</td>
                                    <td>
                                        <input id="plazo"
                                            className="inputboxclienteDetalle"
                                            type="number"
                                            placeholder="Plazo"
                                            required
                                            value={plazo}
                                            maxLength="15"
                                            onChange={(e) => setPlazo(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Tasa de interés</td>
                                    <td>
                                        <input id="intereses"
                                            className="inputboxclienteDetalle"
                                            type="number"
                                            placeholder="Tasa de interes"
                                            required
                                            value={interes}
                                            onChange={(e) => setInteres(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                {(tipo === "Hipotecario UVR Baja" || tipo === "Hipotecario UVR Media") && (
                                    <>
                                        <tr>
                                            <td>Cotiz.inicial $/UVR</td>
                                            <td>
                                                <input id="uvr"
                                                    className="inputboxclienteDetalle"
                                                    type="number"
                                                    placeholder="Cotiz.inicial $/UVR"
                                                    required
                                                    value={uvr}
                                                    maxLength="15"
                                                    onChange={(e) => setUvr(e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Variación anual UVR</td>
                                            <td>
                                                <input id="variacionUvr"
                                                    className="inputboxclienteDetalle"
                                                    type="number"
                                                    placeholder="Variacion anual UVR"
                                                    required
                                                    value={variacionUvr}
                                                    maxLength="15"
                                                    onChange={(e) => setVariacionUvr(e.target.value)}
                                                />
                                            </td>
                                        </tr>
                                    </>
                                )}
                                <tr>
                                    <td>Ingresos</td>
                                    <td>
                                        <input id="ingresos"
                                            className="inputboxclienteDetalle"
                                            type="number"
                                            placeholder="Ingresos"
                                            value={ingresos}
                                            maxLength="15"
                                            onChange={(e) => setIngresos(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Cuota actual</td>
                                    <td>
                                        <input id="cuotaMensual"
                                            className="inputboxclienteDetalle"
                                            type="number"
                                            placeholder="Cuota actual"
                                            value={cuotaMensual}
                                            maxLength="15"
                                            onChange={(e) => setCuotaMensual(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Puntos efectivos cobertura</td>
                                    <td>
                                        <input id="puntosCobertura"
                                            className="inputboxclienteDetalle"
                                            type="number"
                                            placeholder="Puntos efectivos cobertura"
                                            value={puntosCobertura}
                                            maxLength="15"
                                            onChange={(e) => setPuntosCobertura(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>Seguro</td>
                                    <td>
                                        <input id="seguro"
                                            className="inputboxclienteDetalle"
                                            type="number"
                                            placeholder="Seguro"
                                            value={seguro}
                                            maxLength="15"
                                            onChange={(e) => setSeguro(e.target.value)}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer className="ModalDetalleClienteBody">
                        <Button type="button" variant="secondary" onClick={props.modalNuevoEstudioClose}>
                            Cancelar
                        </Button>
                        <Button className="btn-modal-guardar" type="submit">
                            Guardar
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <ModalSpinner showModalSpinner={showModalSpinner}/>
        </div>
    );
}
export default ModalNuevoEstudio;