import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

function PaginationControl(props) {

    return (
        <div>
            <Pagination className="PaginationControl">
                <DropdownButton className="PaginationControlDropDownMenu" variation="--bs-dropdown-bg" id="dropdown-basic-button" title={"Registros por página " + props.rows} >
                    <Dropdown.Item onClick={() => { props.setRows(5); props.setPage(1) }}>5</Dropdown.Item>
                    <Dropdown.Item onClick={() => { props.setRows(10); props.setPage(1) }}>10</Dropdown.Item>
                    <Dropdown.Item onClick={() => { props.setRows(20); props.setPage(1) }}>20</Dropdown.Item>
                </DropdownButton>
                {props.page > 2 && <Pagination.First onClick={()=>props.setPage(1)}/>}
                {props.page > 1 && <Pagination.Item onClick={() => props.setPage(props.page - 1)}>{props.page - 1}</Pagination.Item>}
                <Pagination.Item active >{props.page}</Pagination.Item>
                {props.maxPage > props.page && <Pagination.Item onClick={() => props.setPage(props.page + 1)}>{props.page + 1}</Pagination.Item>}
                {props.maxPage > (props.page + 1) && <Pagination.Last onClick={() => props.setPage(props.maxPage)} />}
            </Pagination>
        </div>
    )
}
export default PaginationControl;