
import apiInstance from '../services/apiConnection';
import { PROPUESTAS_ENDPOINTS } from "../services/apiEndPoints"
import { AlertDescription } from "../components/alerts/AlertDescription";


const listarPropuestas = async(loggedUser, cedula, setEstudio) => {
    if (cedula) {
        const headers = {
            "Authorization": "Bearer " + loggedUser.token,
            "cedula": cedula
        };

        apiInstance.get(PROPUESTAS_ENDPOINTS.LISTARCLIENTE, { headers: headers })
            .then((res) => {
                let data = res.data;
                setEstudio(data);
            })
            .catch((error) => {
                console.log(error);
                AlertDescription("Ups!", "No se pudo cargar la informaci&oacuten del estudio del cr&eacutedito", "error");
            });
    }
}

export default listarPropuestas;