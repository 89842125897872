import React from 'react';
import { useContext } from "react";
import { GlobalDataContext } from "../context/globalData"
import Table from 'react-bootstrap/Table';

function TablaDatosCliente(props) {

    const gdc = useContext(GlobalDataContext);

    function mostrarTabla() {
        if (props.estudio) {
            return (
                <Table className="table table-bordered border-dark" size="sm" >
                    <thead >
                        <tr className="text-center">
                            <th colSpan={2}>
                                DATOS DEL CLIENTE
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td width="50%">Cédula</td>
                            <td width="50%">{gdc.cliente.cedula}</td>
                        </tr>
                        <tr>
                            <td>Nombre</td>
                            <td>
                                {gdc.cliente.nombre} {gdc.cliente.apellido}
                            </td>
                        </tr>
                        <tr>
                            <td>Fecha Nacimiento</td>
                            <td>
                                {gdc.cliente.fechanac ? new Date(gdc.cliente.fechanac).toLocaleDateString() : ""}
                            </td>
                        </tr>
                        <tr>
                            <td>Valor Comercial</td>
                            <td>
                                ${Math.round(props.estudio.valorComercial).toLocaleString()}
                            </td>
                        </tr>
                        <tr>
                            <td>Crédito No.</td>
                            <td>
                                {props.estudio.noCredito ? props.estudio.noCredito : ""}
                            </td>
                        </tr>
                        <tr>
                            <td>Valor Crédito</td>
                            <td>
                                ${props.estudio.valorCredito ? Math.round(props.estudio.valorCredito).toLocaleString() : 0}
                            </td>
                        </tr>
                        <tr>
                            <td>Banco</td>
                            <td>
                                {props.estudio.banco ? props.estudio.banco : ""}
                            </td>
                        </tr>
                        <tr>
                            <td>Ingresos</td>
                            <td>
                                ${props.estudio.ingresos ? Math.round(props.estudio.ingresos).toLocaleString() : 0}
                            </td>
                        </tr>
                        <tr>
                            <td>Cuota máxima</td>
                            <td>
                                ${props.estudio.ingresos ? Math.round(props.estudio.ingresos * 0.3).toLocaleString() : 0}
                            </td>
                        </tr>
                        <tr>
                            <td>Asesor</td>
                            <td>{gdc.cliente.asesor}</td>
                        </tr>
                    </tbody>
                </Table>
                )
        }
    }
    
    return (
        <div>
            {mostrarTabla()}
        </div>
    );
}
export default TablaDatosCliente;